import { React, useEffect, useRef, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useNavigate } from "react-router-dom";
import Dot from '../assets/images/dot.png';
import Add from '../assets/images/add.png';
import Facebook from '../assets/images/facebook.png';
import Instagram from '../assets/images/instagram.png';
import Twitter from '../assets/images/twitter.png';
import Delete from '../assets/images/delete.png';
import HeaderProfileArea from './shared/HeaderProfileArea';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ButtonLoading } from './shared/Buttonloading';
import { useDispatch, useSelector } from 'react-redux';
import { getBotOperators, updateBotOperator, resetUpdateBotOperator, updateBotOperatorStatus, resetUpdateBotOperatorStatus, deleteBotOperator, resetDeleteBotOperator } from '../actions/twitterAction';
import { getRedirectUrl, resetGetRedirectUrl } from '../actions/facebookAction';
import dayjs from "dayjs";
import { logout } from '../helpers/userAuth';
import { emptyRedux } from '../actions/twitterAction';

const ManageAccount = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const getBotOperatorRes = useSelector(state => state.twitterReducer.getBotOperator);

    const updateBotOperatorRes = useSelector(state => state.twitterReducer.updateBotOperator);

    const deleteBotOperatorRes = useSelector(state => state.twitterReducer.deleteBotOperator);

    const updateBotOperatorStatusRes = useSelector(state => state.twitterReducer.updateBotOperatorStatus);

    const getBotOperatorFailed = useSelector(state => state.twitterReducer.getBotOperatorFailed);

    const getRedirectUrlRes = useSelector(state => state.facebookReducer.getRedirectUrl);

    const [modalShow, setModalShow] = useState(false);

    const [botList, setBotList] = useState([]);

    const [chkboxFilter, setChkboxFilter] = useState({"all": true, "twitter": false, "facebook": false, "instagram": false});

    const [removeDataId, setRemoveDataId] = useState(0);

    const [accessToken, setAccessToken] = useState("");

    const [accessSecret, setAccessSecret] = useState("");

    const [appKey, setAppKey] = useState("");

    const [appSecret, setAppSecret] = useState("");

    const [updateBotId, setUpdateBotId] = useState(0);

    const [showErrMsg, setShowErrMsg] = useState(false);

    const [facebookShowErrMsg, setFacebookShowErrMsg] = useState(false);

    const [loadingSubmitBtn, setLoadingSubmitBtn] = useState(false);

    const [editModalShow, setEditModalShow] = useState(false);

    const [facebookEditModalShow, setFacebookEditModalShow] = useState(false);

    const [fbRedirectUrl, setFbRedirectUrl] = useState("");
    

    useEffect(() => {
        dispatch(getBotOperators());
        dispatch(getRedirectUrl());
    },[]);

    useEffect(() => {
        // console.log(getBotOperatorFailed);
        if(getBotOperatorFailed.statusCode == 401){
              toast.error(getBotOperatorFailed.message, {autoClose:6000,onClose: () => {
                    dispatch(emptyRedux());
                    logout();
                    navigate("/login");
              }});
        }
    }, [getBotOperatorFailed]);

    useEffect(() => {
            if (getBotOperatorRes.statusCode == 200) {
                console.log(getBotOperatorRes.data.users);
                let botlist = getBotOperatorRes.data.users;
                const newArray = botlist.map((item) => {
                        return item;
                });

                setBotList([
                        ...newArray
                ]);
            }
    }, [getBotOperatorRes]);

    useEffect(() => {
        if (getRedirectUrlRes.statusCode == 200) {
              setFbRedirectUrl(getRedirectUrlRes.data.redirect_url);
              dispatch(resetGetRedirectUrl());
        }
    }, [getRedirectUrlRes]);

    useEffect(() => {
        
        if (updateBotOperatorRes.statusCode === 200) {

            setTimeout(() => {
                dispatch(resetUpdateBotOperator());
                // setFetched(true);
            }, 1500);
            

            if(updateBotOperatorRes.data.response.hasOwnProperty("status") && updateBotOperatorRes.data.response.status == "error"){
                toast.error("We couldn't update bot account. Please check your API keys and try again!", {autoClose:6000});
            }
            else{
                toast.success('Successfully updated Bot account!', {autoClose:3000});     

                dispatch(getBotOperators());
            }
        }

        setEditModalShow(false);

        setLoadingSubmitBtn(false);

    }, [updateBotOperatorRes]);    

    useEffect(() => {
        
        if (updateBotOperatorStatusRes.status == "success") {

            dispatch(resetUpdateBotOperatorStatus());
        }
    }, [updateBotOperatorStatusRes]);    


    useEffect(() => {
        if (deleteBotOperatorRes.statusCode == 200) {
            dispatch(getBotOperators());
            toast.success('Account removed successfully', {autoClose:3000});
            dispatch(resetDeleteBotOperator());
        }
        setTimeout(() => {
            setModalShow(false);
      }, 1000);
    }, [deleteBotOperatorRes]);

    const filterAccounts = (accounttype, e) => {

        var chkboxFilterAry = chkboxFilter;

        if(accounttype != "all"){
            chkboxFilterAry["all"] = false;
            chkboxFilterAry[accounttype] =  e.target.checked;
        }
        else{
            Object.keys(chkboxFilterAry).forEach(function(key, index) {
                chkboxFilterAry[key] = false;
            });
            chkboxFilterAry["all"] = e.target.checked;
        }

        setChkboxFilter({ ...chkboxFilterAry });
    }

    const changeStatus = (bot_id, e, iteration) => {

        let data = {
            "bot_id": bot_id,
            "accountStatus": e.target.checked ? 1 : 0
        };

        dispatch(updateBotOperatorStatus(data));

        let botListAry = botList;

        botListAry[iteration].accountStatus = e.target.checked ? 1 : 0;

        setBotList([
            ...botListAry
        ]);

        toast.success('Successfully changed the status', {autoClose:3000});
    }

    const confirmDeleteRecord = (bot_id, e) => {

        setRemoveDataId(bot_id);

        setModalShow(true);
    }

    const removeBotOperatorRecord = (bot_id, e) => {

        if(removeDataId !== 0){
            let data = {
                "data" : {
                    "bot_id": removeDataId,
                }
            };
    
            dispatch(deleteBotOperator(data));
        }
        else{
            toast.error('Something went wrong. Please try again!', {autoClose:3000});
        }
    }

    const openEditModal = (key, socialMediaType) => {
        let getBotUser = botList[key];

        setAccessToken(getBotUser.accessToken);
    
        setAccessSecret(getBotUser.accessSecret);
        
        setAppKey(getBotUser.appKey);
        
        setAppSecret(getBotUser.appSecret);

        setUpdateBotId(getBotUser.id);

        if(socialMediaType == "twitter"){
            setEditModalShow(true);
        }
        else if(socialMediaType == "facebook"){
            setFacebookEditModalShow(true);
        }

        
    }

    const handleChange = (input, event) => {
        var value = event.target.value;

        if(input == "accesstoken"){
              setAccessToken(value);
        }

        if(input == "accesssecret"){
              setAccessSecret(value);
        }

        if(input == "appkey"){
              setAppKey(value);
        }

        if(input == "appsecret"){
              setAppSecret(value);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if(accessToken != "" && accessSecret != "" && appKey != "" && appSecret != ""){
            setLoadingSubmitBtn(true);
            setShowErrMsg(false);
            let data = {
                    "bot_id": updateBotId,
                    "access_token": accessToken,
                    "access_secret": accessSecret,
                    "app_key": appKey,
                    "app_secret": appSecret
            };
            dispatch(updateBotOperator(data));
        }   
        else{
            setShowErrMsg(true);
            setLoadingSubmitBtn(false);
            toast.error('Validation failed. Invalid or empty values!', {autoClose:3000});
        }
    };

    const handleFbSubmit = (e) => {
        e.preventDefault();

        if(appKey != "" && appSecret != ""){
            setLoadingSubmitBtn(true);
            setShowErrMsg(false);

            if(fbRedirectUrl != ""){
                window.location.href = "https://www.facebook.com/v17.0/dialog/oauth?client_id="+appKey+"&redirect_uri="+fbRedirectUrl+"&scope=pages_show_list,pages_manage_instant_articles,page_events,pages_manage_posts,pages_read_user_content,pages_manage_engagement,pages_read_engagement,pages_manage_metadata,user_posts,user_link,read_insights&state=BOT___"+appKey+"___"+appSecret+"";
            }
            else{
                toast.error('Something went wrong..! Facebook Redirect url not found.', {autoClose:3000});
            }
        }   
        else{
            setFacebookShowErrMsg(true);
            setLoadingSubmitBtn(false);
            toast.error('Validation failed. Invalid or empty values!', {autoClose:3000});
        }
    };

    return (
        <div className="dashboard manage-account">

            <header>
                <div className="flex">
                    <div className="lft">
                        <h3>Manage Accounts</h3>
                    </div>
                    <HeaderProfileArea/>
                </div>
            </header>
            <div className="main_content innermain swcoontent ">
                <div className='filterarea post_history fadein'>
                    <h4>Filter Accounts</h4>
                    {/* <div className='searchwithbtn'>
                        <input placeholder="Search a Platform" className='form-control searchicon' />
                    </div> */}
                    <div className='checktxt'>
                        <ul className='checktxtlist'>
                            <li>
                             <input type="checkbox" checked={chkboxFilter["all"]} className="form-check-input" id="filterCheckAll" onChange={(e)=>filterAccounts("all",e)} />
                             <label className='checkradiobox' htmlFor="filterCheckAll"> Select all platforms</label>                                        
                            </li>
                            <li>
                             <input type="checkbox" checked={chkboxFilter["twitter"]} className="form-check-input" id="filterCheckTwitter" onChange={(e)=>filterAccounts("twitter",e)} />
                             <label className='checkradiobox' htmlFor="filterCheckTwitter"> <img src={Twitter} alt="" /> Twitter</label>                                        
                            </li>
                            <li>
                             <input type="checkbox" checked={chkboxFilter["facebook"]} className="form-check-input" id="filterCheckFacebook" onChange={(e)=>filterAccounts("facebook",e)} />
                             <label className='checkradiobox' htmlFor="filterCheckFacebook"> <img src={Facebook} alt="" /> Facebook</label>                                        
                            </li>
                            <li>
                             <input type="checkbox" checked={chkboxFilter["instagram"]} className="form-check-input" id="filterCheckInstagram" onChange={(e)=>filterAccounts("instagram",e)} />
                             <label className='checkradiobox' htmlFor="filterCheckInstagram"> <img src={Instagram} alt="" /> Instagram</label>                                        
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="post_history fadein">
                    <div className='row'>
                        <div className='col-md-4'><h4>Accounts</h4></div>
                        <div className='col-md-8'>
                            <div className='searchwithbtn'>
                                {/* <input placeholder="Search an Account" className='form-control searchicon' /> */}
                                <button className="btn btn-primary" onClick={()=>navigate("/connect-account")}><img src={Add} alt="" /> Connect account</button>
                            </div>

                        </div>
                    </div>

                    <Table responsive className='mt-3'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th className='text-start'>Account Name</th>
                                <th>Added date</th>
                                <th>Account Type</th>
                                <th>Status</th>
                                <th>Modify</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {botList.length > 0 ? botList.map((value, key) => {
                                return (<tr  key={key+1} className={chkboxFilter["all"] == false ? (chkboxFilter[value.socialmediaType] ? "" : "d-none")  : "" }>
                                    <td>
                                        <div className='checktxt'>
                                            {/* <input type="checkbox" className="form-check-input" id={"accountCheck"+value.id} />
                                            <label className='checkradiobox' htmlFor={"accountCheck"+value.id}></label> */}
                                            {key+1}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="media_name">
                                            <i>
                                                <img src={value.socialmediaType == "twitter" ? Twitter : (value.socialmediaType == "facebook" ? Facebook : Instagram )} alt="" />
                                            </i>
                                            <span>{value.accountName}</span>
                                        </div>
                                    </td>
                                    <td>{dayjs(new Date(value.createdAt)).format('DD-MMM-YYYY')}</td>
                                    <td className='text-capitalize'>{value.accountType}</td>
                                    {/*<td><span className='badge badge-primary'>{value.accountStatus == 1 ? "APPROVED" : "PENDING"}</span></td>*/}
                                    <td>
                                        <span className='togglebtn'>
                                            <input type="checkbox" defaultChecked={value.accountStatus == 1 ? true : false} id={"switchStatus" + value.id} onChange={(e)=>changeStatus(value.id, e, key)} />
                                            <label htmlFor={"switchStatus" + value.id}></label>
                                        </span>
                                    </td>
                                    <td>
                                        <button type="button" onClick={(e)=>openEditModal(key, value.socialmediaType)} className="btn btn-primary-outline">Edit</button>
                                    </td>
                                    <td>
                                        <button type="button" className="bg-transparent border-0" onClick={(e)=>confirmDeleteRecord(value.id, e)}>
                                            <img src={Delete} alt="" />
                                        </button>
                                    </td>
                                </tr>); 
                            }) 
                            :
                            <tr>
                                <td colSpan="5" className="text-center">No records were found.</td>
                            </tr> 
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='connet_modal cm-bg-none fadein'
            >
                <div className="bgs">
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                    Do you want to remove this record?
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="frm">
                                    <button type='button' className="s_btn" onClick={() => removeBotOperatorRecord()}>
                                        <span> Confirm</span>
                                        <ButtonLoading />
                                    </button>
                            </div>
                            <div className="frm">
                                    <button type='button' className="sr_btn" onClick={() => setModalShow(false)}>
                                        <span> Cancel</span>
                                        <ButtonLoading />
                                    </button>
                            </div>
                        </Modal.Body>
                </div>
            </Modal>
            <Modal
                show={editModalShow}
                onHide={() => setEditModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='connet_modal fadein'
            >
                <div className="bgs">
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                                Update Profile
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                                <div className="frm">
                                    <input type="text" id='token' name='token' placeholder='Access Token' value={accessToken} onChange={(e) => handleChange("accesstoken", e)} />
                                    <label htmlFor='token'>Access Token *</label>
                                </div>
                                <div className="frm">
                                    <input type="text" id='secret' name='secret' placeholder='Access Secret' value={accessSecret} onChange={(e) => handleChange("accesssecret", e)} />
                                    <label htmlFor='secret'>Access Secret *</label>
                                </div>
                                <div className="frm">
                                    <input type="text" id='key' name='key' placeholder='App Key' value={appKey} onChange={(e) => handleChange("appkey", e)} />
                                    <label htmlFor='key'>App Key *</label>
                                </div>
                                <div className="frm">
                                    <input type="text" id='app_secret' name='app_secret' placeholder='App Secret' value={appSecret} onChange={(e) => handleChange("appsecret", e)} />
                                    <label htmlFor='app_secret'>App Secret *</label>
                                </div>
                                <div className={"frm " + (showErrMsg ? "" : "d-none")}>
                                    <p><small className="text-danger">Please fill all the required fields.</small></p>
                                </div>
                                <div className="frm">
                                    <button type='submit' className={'s_btn ' + (loadingSubmitBtn ? "btn_loading" : "")}>
                                            <span> Submit</span>
                                            <ButtonLoading />
                                    </button>
                                </div>
                        </form>
                    </Modal.Body>
                </div>
            </Modal>
            <Modal
                show={facebookEditModalShow}
                onHide={() => setFacebookEditModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='connet_modal fadein'
            >
                <div className="bgs">
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                    Update Facebook Profile
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={handleFbSubmit}>
                                    <div className="frm">
                                        <input type="text" id='fb_app_id' name='app_id' placeholder='App ID' onChange={(e) => handleChange("appkey", e)} value={appKey}/>
                                        <label htmlFor='app_id'>App ID *</label>
                                    </div>
                                    <div className="frm">
                                        <input type="text" id='fb_app_secret' name='app_secret' placeholder='App Secret' onChange={(e) => handleChange("appsecret", e)} value={appSecret} />
                                        <label htmlFor='app_secret'>App Secret *</label>
                                    </div>
                                    <div className={"frm " + (facebookShowErrMsg ? "" : "d-none")}>
                                        <p><small className="text-danger">Please fill all the required fields.</small></p>
                                    </div>
                                    <div className="frm">
                                        <button type='submit' className={'s_btn ' + (loadingSubmitBtn ? "btn_loading" : "")}>
                                                <span> Submit</span>
                                                <ButtonLoading />
                                        </button>
                                    </div>
                            </form>
                        </Modal.Body>
                </div>
            </Modal>
            <ToastContainer />
        </div>
    )
}


export default ManageAccount;