import { React, useEffect, useRef, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import HeaderProfileArea from './shared/HeaderProfileArea';
import { ButtonLoading } from './shared/Buttonloading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { createTweet, resetCreateTweet, getActiveBotOperators, resetGetBotOperators, resetActiveBotOperatorsFailed } from '../actions/twitterAction';
import { getFbBotOperators, resetGetFbBotOperators, createPost, resetCreatePost } from '../actions/facebookAction';
import CreatePostimg from '../assets/images/art.png';
import { logout } from '../helpers/userAuth';
import { useNavigate } from 'react-router-dom';
import { emptyRedux } from '../actions/twitterAction';
import Exclusive from '../assets/images/exclusive.png'

const CreatePost = () => {
      
      const dispatch = useDispatch();
      const navigate = useNavigate();

      const createTweetRes = useSelector(state => state.twitterReducer.createTweet);
      const getActiveBotOperatorRes = useSelector(state => state.twitterReducer.getActiveBotOperator);
      const getActiveBotOperatorFailed = useSelector(state => state.twitterReducer.getActiveBotOperatorFailed);

      //const getFacebookPagesRes = useSelector(state => state.facebookReducer.getFacebookPages);
      //const getUserPagesRes = useSelector(state => state.facebookReducer.getUserPages);
      const createPostRes = useSelector(state => state.facebookReducer.createPost);

      const getFbBotOperatorsRes = useSelector(state => state.facebookReducer.getFbBotOperators);

      const [key, setKey] = useState('Twitter');

      const [twitterBotList, setTwitterBotList] = useState([]);

      const [facebookBotList, setFacebookBotList] = useState([]);

      const [facebookBotDetailList, setFacebookBotDetailList] = useState([]);

      const [botselectors, setBotselectors] = useState([]);

      const [facebookBotselector, setFacebookBotselector] = useState(0);

      const [facebookPageselector, setFacebookPageselector] = useState(0);

      const [loadingTwnBtn, setLoadingTwnBtn] = useState(false);

      const [loadingFbBtn, setLoadingFbBtn] = useState(false);

      const [tweetTextInput, setTweetTextInput] = useState("");

      const [fbPostTextInput, setFbPostTextInput] = useState("");

      const [facebookPageList, setFacebookPageList] = useState([]);

      const [filterFbPageList, setFilterFbPageList] = useState([]);

      const select_bot_op_multi = true;

      const maxLength = 280;

      const [errorMessage, setErrorMessage] = useState('');

      const [containerStyle, setContainerStyle] = useState({
            border: '1px solid #9CC2C1',
          });
      
      useEffect(() => {
            dispatch(getActiveBotOperators());
            dispatch(getFbBotOperators());
            //dispatch(getPages());
            //dispatch(getUserPages());
      },[]);

      useEffect(() => {
            if(getActiveBotOperatorFailed.statusCode == 401){
                  dispatch(resetActiveBotOperatorsFailed());
                  toast.error(getActiveBotOperatorFailed.message, {autoClose:6000,onClose: () => {
                        dispatch(emptyRedux());
                        logout();
                        navigate("/login");
                  }});
            }
      },[getActiveBotOperatorFailed]);

      useEffect(() => {
            if (createTweetRes.statusCode == 200) {
                  setTimeout(() => {
                        dispatch(resetCreateTweet());
                        // setFetched(true);
                  }, 1500);
                  setLoadingTwnBtn(false);
                  setTweetTextInput("");
                  //setBotselectors([]);
                  toast.success('Successfully posted new tweet!', {autoClose:3000});
            }
      }, [createTweetRes]);

      useEffect(() => {
            if (typeof createPostRes.statusCode != "undefined" && createPostRes.statusCode == 200) {
                  setTimeout(() => {
                        dispatch(resetCreatePost());
                  }, 1500);

                  setLoadingFbBtn(false);
                  setFbPostTextInput("");
                  //setFacebookBotselector([]);
                  toast.success('Successfully created new post!', {autoClose:3000});
            }
      }, [createPostRes]);
      
      useEffect(() => {
            if (getActiveBotOperatorRes.statusCode == 200) {
                  console.log(getActiveBotOperatorRes.data.users);
                  let botlist = getActiveBotOperatorRes.data.users;

                  const twitterList = botlist.filter((item) => {
                        return item.socialmediaType == "twitter" ? true : false;
                  }).map((item) => {
                        return { value: `${item.id}`, label: `${item.accountName}` };
                  });

                  setTwitterBotList(twitterList);

                  const facebookList = botlist.filter((item) => {
                        return item.socialmediaType == "facebook" ? true : false;
                  }).map((item) => {
                        return { value: `${item.id}`, label: `${item.accountName}` };
                  });

                  setFacebookBotList(facebookList);
            }
      }, [getActiveBotOperatorRes]);



      useEffect(() => {
            if (getFbBotOperatorsRes.statusCode == 200) {
                  console.log(getFbBotOperatorsRes.data.users);
                  let botlist = getFbBotOperatorsRes.data.users;

                  const fbOptionList = botlist.filter((item) => {
                        return item.accountStatus === 1 ? true : false;
                  }).map((item) => {
                        return { value: `${item.id}`, label: `${item.accountName}` };
                  });

                  setFacebookBotList(fbOptionList);

                  setFacebookBotDetailList(botlist);
            }
      }, [getFbBotOperatorsRes]);

      

      /*************START**************/

      /*useEffect(() => {
            if (getFacebookPagesRes.statusCode == 200) {

                  setFacebookPageList([
                        ...getFacebookPagesRes.data.pages
                  ]);
                  dispatch(resetGetPages());
            }
      }, [getFacebookPagesRes]);

      useEffect(() => {
            if (getUserPagesRes.statusCode == 200) {

                  setFacebookPageList([
                        ...getUserPagesRes.data.pages
                  ]);
                  dispatch(resetGetUserPages());
            }
      }, [getUserPagesRes]);*/

      /*************END**************/

      const handleBotOperatorChanges = (options) => {
            
            let value = [];
            for (var i = 0, l = options.length; i < l; i++) {
                value.push(options[i].value);
            }
            
            setBotselectors([
                  ...value
            ]);
      }

      const handleFacebookBotOperatorChanges = (options) => {
            
            setFacebookBotselector(options.value);

            let userPages = [];

            facebookBotDetailList.forEach((user,i)=>{
                  if(user.id == options.value){
                        userPages = user.userPages;
                  }
            });

            console.log("+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=++=");
            console.log(facebookBotDetailList);
            console.log(userPages);
            console.log("+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=++=");

            const filterFbPage = userPages.map((item) => {
                  return { value: `${item.pageId}`, label: `${item.pageName}` };
            });

            setFilterFbPageList([
                  ...filterFbPage
            ]);

      }

      const handleFacebookPageChanges = (options) => {

            /*let value = [];

            for (var i = 0, l = options.length; i < l; i++) {
                value.push(options[i].value);
            }*/

            console.log(options);
            
            setFacebookPageselector(options.value);
      }

      const handleTweetTextChanges = (event) => {
            var value = event.target.value;
            setTweetTextInput(value);
            if (value.length > maxLength) {
                  setErrorMessage(`Character limit exceeded! Maximum allowed: ${maxLength}`);   
            } else {
                  setContainerStyle({ border: '1px solid #9CC2C1' });
                  setErrorMessage('');
                 


            }
      }

      const handleTweetTextKeyPress = (event) => {
            if (tweetTextInput.length > maxLength) {
            event.preventDefault();
            setContainerStyle({ border: '1px solid #ff7171' });
            setErrorMessage(`Character limit exceeded! Maximum allowed: ${maxLength}`);   
            }
      };

      const handleFbPostTextChanges = (event) => {
            var value = event.target.value;
            
            setFbPostTextInput(value);
      }
      
      const handleTweetSubmit = (event) => {
            event.preventDefault();

            setLoadingTwnBtn(true);

            const tweetValue = event.target.elements.tweet.value;
            if(tweetValue == ""){
                  toast.error("Tweet message should not be empty.", {autoClose:5000});
                  setLoadingTwnBtn(false);
                  return;
            }

            if (tweetValue.length >= maxLength) {
                  toast.error("Character limit exceeded! Maximum allowed: 280", {autoClose:5000});
                  setLoadingTwnBtn(false);
                  return; 
            }

            if(botselectors.length > 0){
                  botselectors.map((item) => { 
                        let data = {
                              "tweet_message": tweetValue,
                              "bot_id": item
                        }
                        dispatch(createTweet(data));
                  });
            }
            else{
                  toast.error("Please select any users to post the tweet.", {autoClose:5000});
                  setLoadingTwnBtn(false);
            }
      }

      const handleFbPostSubmit = (event) => {
            event.preventDefault();

            setLoadingFbBtn(true);

            const postValue = event.target.elements.fbpost.value;

            if(postValue == ""){
                  toast.error("Post message should not be empty.", {autoClose:5000});
                  setLoadingFbBtn(false);
                  return;
            }


            if(facebookBotselector != 0 && facebookPageselector != 0){

                  let data = {
                        "post_message": postValue,
                        "page_id": facebookPageselector,
                        "bot_id": facebookBotselector
                  }
                  dispatch(createPost(data));
            }
            else{
                  toast.error("Please select any user/page to post the message.", {autoClose:5000});
                  setLoadingFbBtn(false);
            }
      }

             

      const animatedComponents = makeAnimated();

      return (
            <div className="dashboard">
                  <header>
                        <div className="flex">
                              <div className="lft">
                                    <h3>Create Post</h3>
                              </div>
                              <HeaderProfileArea/>
                        </div>
                  </header>
                  <div className="main_content innermain">
                        <div className="create_post">
                              <Tabs
                                    defaultActiveKey="profile"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                              >
                                    <Tab eventKey="Twitter" title="Twitter">
                                          <form onSubmit={handleTweetSubmit} className='fadein'>
                                                <div className="tab-cnt" style={containerStyle}>
                                                <div style={{ color: 'red' }}>{errorMessage}</div>
                                                      <div className="frm">
                                                            <textarea name="tweet" placeholder='Type post description here' value={tweetTextInput} onChange={handleTweetTextChanges} onKeyPress={handleTweetTextKeyPress} />
                                                      </div>
                                                      <div className="frm">
                                                            <Select
                                                                  closeMenuOnSelect={true}
                                                                  components={animatedComponents}
                                                                  isMulti={select_bot_op_multi}
                                                                  options={twitterBotList}
                                                                  onChange={handleBotOperatorChanges}
                                                            />
                                                      </div>
                                                </div>
                                                <button type="submit" className={"send_btn " + (loadingTwnBtn ? "btn_loading" : "")}>
                                                      <span> Tweet</span>
                                                      <ButtonLoading />
                                                </button>
                                          </form>
                                    </Tab>
                                    <Tab eventKey="Facebook" title="Facebook">
                                          {/*<div className='csitxt fadein'>
                                                <img src={CreatePostimg} alt="" />
                                                <h4 className='mt-3 text-uppercase text-secondary'>Coming Soon</h4>
                                          </div>*/}
                                          <form onSubmit={handleFbPostSubmit} className='fadein'>
                                                <div className="tab-cnt">
                                                      <div className="frm">
                                                            <textarea name="fbpost" placeholder='Type post description here' value={fbPostTextInput} onChange={handleFbPostTextChanges} />
                                                      </div>                                                      
                                                      <div className="frm">
                                                            <div class="row">
                                                                  <div className="col-md-6 col-sm-6">
                                                                        <Select
                                                                              closeMenuOnSelect={true}
                                                                              components={animatedComponents}
                                                                              isMulti={false}
                                                                              options={facebookBotList}
                                                                              onChange={handleFacebookBotOperatorChanges}
                                                                              placeholder="Select user"
                                                                        />
                                                                  </div>
                                                                  <div className="col-md-6 col-sm-6">
                                                                        <Select
                                                                              closeMenuOnSelect={true}
                                                                              components={animatedComponents}
                                                                              isMulti={false}
                                                                              options={filterFbPageList}
                                                                              onChange={handleFacebookPageChanges}
                                                                              placeholder="Select Page"
                                                                        />
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                                <button type="submit" className={"send_btn " + (loadingFbBtn ? "btn_loading" : "")}>
                                                      <span> Post</span>
                                                      <ButtonLoading />
                                                </button>
                                          </form>
                                    </Tab>
                                    <Tab eventKey="Instagram" title="Instagram">
                                    <div className='csitxt fadein'>
                                                <img src={CreatePostimg} alt="" />
                                                <h4 className='mt-3 text-uppercase text-secondary'>Coming Soon</h4>
                                          </div>
                                          {/* <div className="tab-cnt">
                                                <form>
                                                      <div className="frm">
                                                            <textarea placeholder='Type post description here'></textarea>
                                                      </div>
                                                      <div className="frm">
                                                            <Select
                                                                  closeMenuOnSelect={false}
                                                                  components={animatedComponents}
                                                                  defaultValue={[options[0], options[1], options[2]]}
                                                                  isMulti
                                                                  options={options}
                                                            />
                                                      </div>
                                                </form>
                                          </div>
                                          <button className="send_btn">Post</button> */}
                                    </Tab>
                              </Tabs>
                        </div>
                  </div>
                  <ToastContainer />
            </div>
      )
}


export default CreatePost;