import { React, useEffect, useRef, useState } from 'react';
import Exclusive from '../assets/images/exclusive.png'
import TwtBig from '../assets/images/_twitter_.png'
import FbBig from '../assets/images/_facebook_.png'
import InstaBig from '../assets/images/insta_big.png'
import Plus from '../assets/images/plus_icon.png'
import HeaderProfileArea from './shared/HeaderProfileArea';
import Modal from 'react-bootstrap/Modal';
import { ComingSoonModal } from './shared/ComingsoonPopup';
import { ButtonLoading } from './shared/Buttonloading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { createBotOperator, resetcreateBotOperator, getBotOperators } from '../actions/twitterAction';
import { getRedirectUrl, resetGetRedirectUrl } from '../actions/facebookAction';
import { logout } from '../helpers/userAuth';
import { useNavigate } from 'react-router-dom';
import { emptyRedux } from '../actions/twitterAction';

const ConnectAccount = () => {

      const navigate = useNavigate();

      const [modalShow, setModalShow] = useState(false);

      const [facebookModalShow, setFacebookModalShow] = useState(false);

      const [comingsoonModalShow, setComingsoonModalShow] = useState(false);

      const [accessToken, setAccessToken] = useState("");

      const [accessSecret, setAccessSecret] = useState("");

      const [appKey, setAppKey] = useState("");

      const [appSecret, setAppSecret] = useState("");

      const [fbAppId, setFbAppId] = useState("");

      const [fbAppSecret, setFbAppSecret] = useState("");

      const dispatch = useDispatch();

      const createBotOperatorRes = useSelector(state => state.twitterReducer.createBotOperator);
      
      const getBotOperatorRes = useSelector(state => state.twitterReducer.getBotOperator);

      const getBotOperatorFailed = useSelector(state => state.twitterReducer.getBotOperatorFailed);

      const getRedirectUrlRes = useSelector(state => state.facebookReducer.getRedirectUrl);

      const [twitterBotList, setTwitterBotList] = useState([]);

      const [facebookBotList, setFacebookBotList] = useState([]);

      const [instagramBotList, setInstagramBotList] = useState([]);

      const [showErrMsg, setShowErrMsg] = useState(false);

      const [facebookShowErrMsg, setFacebookShowErrMsg] = useState(false);

      const [loadingTwtBtn, setLoadingTwtBtn] = useState(false);

      const [loadingFbBtn, setLoadingFbBtn] = useState(false);

      const [fbRedirectUrl, setFbRedirectUrl] = useState("");

      useEffect(() => {
            dispatch(getBotOperators());
            dispatch(getRedirectUrl());
      },[]);

      useEffect(() => {
            // console.log(getBotOperatorFailed);
            if(getBotOperatorFailed.statusCode == 401){
                  toast.error(getBotOperatorFailed.message, {autoClose:6000,onClose: () => {
                        dispatch(emptyRedux());
                        logout();
                        navigate("/login");
                  }});
            }
      }, [getBotOperatorFailed]);

      useEffect(() => {
            if (getBotOperatorRes.statusCode == 200) {
                  console.log(getBotOperatorRes.data.users);
                  let botlist = getBotOperatorRes.data.users;
                  const newArray = botlist.filter((item) => {
                        if(item.socialmediaType == "twitter"){
                              return true;
                        }
                        return false;
                  }).map((item) => {
                        console.log(item);
                        return item;
                  });

                  setTwitterBotList([
                        ...newArray
                  ]);

                  const fbNewArray = botlist.filter((item) => {
                        if(item.socialmediaType == "facebook"){
                              return true;
                        }
                        return false;
                  }).map((item) => {
                        return item;
                  });

                  setFacebookBotList([
                        ...fbNewArray
                  ]);
                  
            }
      }, [getBotOperatorRes]);

      useEffect(() => {
            if (getRedirectUrlRes.statusCode == 200) {
                  setFbRedirectUrl(getRedirectUrlRes.data.redirect_url);
                  dispatch(resetGetRedirectUrl());
            }
      }, [getRedirectUrlRes]);

      useEffect(() => {
            console.log("createBotOperatorRes", createBotOperatorRes);
            if (createBotOperatorRes.statusCode == 200) {
                  setTimeout(() => {
                        dispatch(resetcreateBotOperator());
                        // setFetched(true);
                  }, 1500);
                  dispatch(getBotOperators());

                  if(createBotOperatorRes.data.response.hasOwnProperty("status") && createBotOperatorRes.data.response.status == "error"){
                        toast.error("We couldn't create bot account. Please check your API keys and try again!", {autoClose:3000});
                  }
                  else{
                        toast.success('Successfully added new Bot account!', {autoClose:3000});     
                  }
            }

            setLoadingTwtBtn(false);
      }, [createBotOperatorRes]);

      const handleChange = (input, event) => {
            var value = event.target.value;

            if(input == "accesstoken"){
                  setAccessToken(value);
            }

            if(input == "accesssecret"){
                  setAccessSecret(value);
            }

            if(input == "appkey"){
                  setAppKey(value);
            }

            if(input == "appsecret"){
                  setAppSecret(value);
            }
      };

      const handleChangeFb = (input, event) => {
            var value = event.target.value;

            if(input == "app_id"){
                  setFbAppId(value);
            }

            if(input == "app_secret"){
                  setFbAppSecret(value);
            }
      };

      const handleSubmit = (e) => {
            e.preventDefault();

            if(accessToken != "" && accessSecret != "" && appKey != "" && appSecret != ""){
                  setLoadingTwtBtn(true);
                  setShowErrMsg(false);
                  let data = {
                        "access_token": accessToken,
                        "access_secret": accessSecret,
                        "app_key": appKey,
                        "app_secret": appSecret
                  };
                  dispatch(createBotOperator(data));
                  setModalShow(false);
            }
            else{
                  setShowErrMsg(true);
                  toast.error('Validation failed. Invalid or empty values!', {autoClose:3000});
            }
      };

      const handleSubmitFb = (e) => {
            e.preventDefault();

            if(fbAppId != "" && fbAppSecret != ""){
                  setLoadingFbBtn(true);
                  setFacebookShowErrMsg(false);
                  
                  setModalShow(false);

                  if(fbRedirectUrl != ""){
                        window.location.href = "https://www.facebook.com/v17.0/dialog/oauth?client_id="+fbAppId+"&redirect_uri="+fbRedirectUrl+"&scope=pages_show_list,pages_manage_instant_articles,page_events,pages_manage_posts,pages_read_user_content,pages_manage_engagement,pages_read_engagement,pages_manage_metadata,user_posts,user_link,read_insights&state=BOT___"+fbAppId+"___"+fbAppSecret+"";
                  }
                  else{
                        toast.error('Something went wrong..! Facebook Redirect url not found.', {autoClose:3000});
                  }
            }
            else{
                  setFacebookShowErrMsg(true);
                  toast.error('Validation failed. Invalid or empty values!', {autoClose:3000});
            }
      };

      return (
            <>
                  <div className="dashboard">
                        <header>
                              <div className="flex">
                                    <div className="lft">
                                          <h3>Connect Account</h3>
                                    </div>
                                    <HeaderProfileArea />
                              </div>
                        </header>
                        <div className="main_content">
                              <div className="connect_act fadein">
                                    <div className="row">
                                          <div className="col-md-3">
                                                <div className="box">
                                                      <div className="top">
                                                            <div className="lft">
                                                                  <h5>Twitter</h5>
                                                                  <button variant="primary" onClick={() => setModalShow(true)}>Connect profile</button>
                                                            </div>
                                                            <div className="rft">
                                                                  <img src={TwtBig} alt="" />
                                                            </div>
                                                      </div>
                                                      <div className="bottom">
                                                            <h4>Connected</h4>
                                                            <ul>
                                                                  {twitterBotList.map((value, key) => {
                                                                        return (<li key={value.accountUsername}>
                                                                              <a href={'https://twitter.com/'+value.accountUsername} target='_blank'>{value.accountUsername} ({value.accountName})
                                                                              { value.accountType == "paid" ?
                                                                                    <img src={Exclusive} alt="" />
                                                                                    :""
                                                                              }
                                                                              </a>
                                                                        </li>); 
                                                                  })}
                                                            </ul>
                                                            <button className='view_all'>
                                                                  View all
                                                            </button>
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="col-md-3">
                                                <div className="box">
                                                      <div className="top">
                                                            <div className="lft">
                                                                  <h5>Facebook</h5>
                                                                  <button variant="primary" onClick={() => setFacebookModalShow(true)}>Connect profile</button>
                                                            </div>
                                                            <div className="rft">
                                                                  <img src={FbBig} alt="" />
                                                            </div>
                                                      </div>
                                                      <div className="bottom">
                                                            <h4>Connected</h4>
                                                            <ul>
                                                                  {facebookBotList.map((value, key) => {
                                                                        return (<li>
                                                                              <a href="javascript:void(0);">{value.accountName}</a>
                                                                        </li>); 
                                                                  })}
                                                            </ul>
                                                            <button className='view_all'>
                                                                  View all
                                                            </button>
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="col-md-3">
                                                <div className="box">
                                                      <div className="top">
                                                            <div className="lft">
                                                                  <h5>Instagram</h5>
                                                                  <button variant="primary" onClick={() => setComingsoonModalShow(true)}>Connect profile</button>
                                                            </div>
                                                            <div className="rft">
                                                                  <img src={InstaBig} alt="" />
                                                            </div>
                                                      </div>
                                                      <div className="bottom">
                                                            <h4>Connected</h4>
                                                            <ul>
                                                                  {instagramBotList.map((value, key) => {
                                                                        return (<li>
                                                                              <a href="javascript:void(0);">{value}</a>
                                                                        </li>); 
                                                                  })}
                                                            </ul>
                                                            <button className='view_all'>
                                                                  View all
                                                            </button>
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="col-md-3">
                                                <div className="box">
                                                      <div className="add">
                                                            <a href="#">
                                                                  <img src={Plus} alt="" />
                                                                  <span>Add account</span>
                                                            </a>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <ComingSoonModal
                        show={comingsoonModalShow}
                        onHide={() => setComingsoonModalShow(false)}
                  ></ComingSoonModal>
                  <Modal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className='connet_modal fadein'
                  >
                        <div className="bgs">
                              <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                          Connect Profile
                                    </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                    <form onSubmit={handleSubmit}>
                                          <div className="frm">
                                                <input type="text" id='token' name='token' placeholder='Access Token' onChange={(e) => handleChange("accesstoken", e)} />
                                                <label htmlFor='token'>Access Token *</label>
                                          </div>
                                          <div className="frm">
                                                <input type="text" id='secret' name='secret' placeholder='Access Secret' onChange={(e) => handleChange("accesssecret", e)} />
                                                <label htmlFor='secret'>Access Secret *</label>
                                          </div>
                                          <div className="frm">
                                                <input type="text" id='key' name='key' placeholder='App Key' onChange={(e) => handleChange("appkey", e)} />
                                                <label htmlFor='key'>App Key *</label>
                                          </div>
                                          <div className="frm">
                                                <input type="text" id='app_secret' name='app_secret' placeholder='App Secret' onChange={(e) => handleChange("appsecret", e)} />
                                                <label htmlFor='app_secret'>App Secret *</label>
                                          </div>
                                          <div className={"frm " + (showErrMsg ? "" : "d-none")}>
                                                <p><small className="text-danger">Please fill all the required fields.</small></p>
                                          </div>
                                          <div className="frm">
                                                <button type='submit' className={'s_btn ' + (loadingTwtBtn ? "btn_loading" : "")}>
                                                      <span> Submit</span>
                                                      <ButtonLoading />
                                                </button>
                                          </div>
                                    </form>
                              </Modal.Body>
                        </div>
                  </Modal>

                  <Modal
                        show={facebookModalShow}
                        onHide={() => setFacebookModalShow(false)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className='connet_modal fadein'
                  >
                        <div className="bgs">
                              <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                          Connect Facebook Profile
                                    </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                    <form onSubmit={handleSubmitFb}>
                                          <div className="frm">
                                                <input type="text" id='fb_app_id' name='app_id' placeholder='App ID' onChange={(e) => handleChangeFb("app_id", e)} />
                                                <label htmlFor='app_id'>App ID *</label>
                                          </div>
                                          <div className="frm">
                                                <input type="text" id='fb_app_secret' name='app_secret' placeholder='App Secret' onChange={(e) => handleChangeFb("app_secret", e)} />
                                                <label htmlFor='app_secret'>App Secret *</label>
                                          </div>
                                          <div className={"frm " + (facebookShowErrMsg ? "" : "d-none")}>
                                                <p><small className="text-danger">Please fill all the required fields.</small></p>
                                          </div>
                                          <div className="frm">
                                                <button type='submit' className={'s_btn ' + (loadingFbBtn ? "btn_loading" : "")}>
                                                      <span> Submit</span>
                                                      <ButtonLoading />
                                                </button>
                                          </div>
                                    </form>
                              </Modal.Body>
                        </div>
                  </Modal>
                  <ToastContainer />
            </>
      )
}


export default ConnectAccount;