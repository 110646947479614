import { React, useEffect, useRef, useState } from 'react';
import Facebook_circle from '../assets/images/Facebook-circle.png';
import Instagram_circle from '../assets/images/insta-circle.png';
import Twitter_circle from '../assets/images/twitter-circle.png';
import User from '../assets/images/prof.png';
import Chatgpt from '../assets/images/chatgpt-icon.png';
import Tweetlikebtn from '../assets/images/tweet_likebtn.png';
import Tweet_repost_icon from '../assets/images/tweet_repost_icon.png';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Send from '../assets/images/send.png';
import HeaderProfileArea from './shared/HeaderProfileArea';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonLoading } from './shared/Buttonloading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getRecentNewsFeed, resetGetRecentNewsFeed, getBotOperators, resetGetBotOperators, replyTweet, resetReplyTweet, getReplyTweets, resetGetReplyTweets, resetGetNewsFeedFailed, getLastCronRunTime, resetGetLastCronRunTime, likeTweet, resetLikeTweet, getLikeTweets, resetGetLikeTweets, retweetTweet, resetRetweetTweet, getReTweets, resetGetReTweets } from '../actions/twitterAction';
import { getFbBotOperators, resetGetFbBotOperators, commentPost, resetCommentPost, getPostComments, resetGetPostComments } from '../actions/facebookAction';
import { askOpenAi, resetAskOpenAi } from '../actions/openaiAction';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DClose from '../assets/images/dclose.png';
import ExtLink from '../assets/images/ext.png';
import Loader from './shared/Loader';
import { logout } from '../helpers/userAuth';
import { useNavigate } from 'react-router-dom';
import { emptyRedux } from '../actions/twitterAction';
import Post_img1 from '../assets/images/post_img1.jpg';
import Post_img2 from '../assets/images/post_img2.jpg';
import Post_img3 from '../assets/images/post_img3.jpg';
import Post_img4 from '../assets/images/post_img4.jpg';

const RecentActivity = () => {

      dayjs.extend(relativeTime);

      const dispatch = useDispatch();
      const navigate = useNavigate();

      const createTweetRes = useSelector(state => state.twitterReducer.createTweet);

      const getRecentNewsFeedRes = useSelector(state => state.twitterReducer.getRecentNewsFeed);

      const openAIRes = useSelector(state => state.twitterReducer.getOpenAiPrompt);

      const openAIRes_rid = useSelector(state => state.twitterReducer.rid);

      const getBotOperatorRes = useSelector(state => state.twitterReducer.getBotOperator);

      const replyTweetRes = useSelector(state => state.twitterReducer.replyTweet);

      const replyTweetRes_rid = useSelector(state => state.twitterReducer.replyTweetRid);

      const getReplyTweetsRes = useSelector(state => state.twitterReducer.getReplyTweets);

      const commentPostRes = useSelector(state => state.facebookReducer.commentPost);

      const commentPostRes_rid = useSelector(state => state.facebookReducer.commentPostRid);

      const getNewsfeedFailed = useSelector(state => state.twitterReducer.getAllNewsFeedFailed);

      const getLastCronRunTimeRes = useSelector(state => state.twitterReducer.getLastCronRunTime);

      const getPostCommentsRes = useSelector(state => state.facebookReducer.getPostComments);

      const getFbBotOperatorsRes = useSelector(state => state.facebookReducer.getFbBotOperators);

      const likeTweetRes = useSelector(state => state.twitterReducer.likeTweet);

      const likeTweetRes_rid = useSelector(state => state.twitterReducer.likeTweetRid);

      const getLikeTweetsRes = useSelector(state => state.twitterReducer.getLikeTweets);

      const retweetTweetRes = useSelector(state => state.twitterReducer.retweetTweet);

      const retweetTweetRes_rid = useSelector(state => state.twitterReducer.retweetTweetRid);

      const getReTweetsRes = useSelector(state => state.twitterReducer.getReTweets);

      const [recentActivities, setRecentActivities] = useState([]);

      const [showmsgbox, setShowmsgbox] = useState([]);      

      const [showlikebox, setShowlikebox] = useState([]);

      const [showrepostbox, setShowrepostbox] = useState([]);

      const [twitterBotList, setTwitterBotList] = useState([]);

      const [twitterPaidBotList, setTwitterPaidBotList] = useState([]);

      const [twitterBotOperatorDetails, setTwitterBotOperatorDetails] = useState([]);

      const [facebookBotList, setFacebookBotList] = useState([]);

      const [facebookBotOperatorDetails, setFacebookBotOperatorDetails] = useState([]);

      const [botselectors, setBotselectors] = useState([]);

      const [replyTweetList, setReplyTweetList] = useState({});

      const [replyText, setReplyText] = useState([]);

      const [loadingBtnOpenAi, setLoadingBtnOpenAi] = useState([]);

      const [loadingBtnReply, setLoadingBtnReply] = useState([]);

      const [loadingBtnLike, setLoadingBtnLike] = useState([]);

      const [loadingBtnRepost, setLoadingBtnRepost] = useState([]);

      const [loadingNewsfeed, setLoadingNewsfeed] = useState(true);

      const [lastCronRunTime, setLastCronRunTime] = useState('');

      const maxLength = 280;

      const [errorMessage, setErrorMessage] = useState('');

      const [showerrbox, setShowerrbox] = useState([]);


      useEffect(() => {
            dispatch(getBotOperators());
            dispatch(getFbBotOperators());
            dispatch(getRecentNewsFeed());
            dispatch(getLastCronRunTime());
            setInterval(() => {
                  dispatch(getLastCronRunTime());
            }, 60000);
            
      }, []);

      useEffect(() => {
            if (getLastCronRunTimeRes.statusCode == 200) {

                  setLastCronRunTime(getLastCronRunTimeRes.data.lastRunTime);

                  dispatch(resetGetLastCronRunTime());
            }
      }, [getLastCronRunTimeRes]);

      useEffect(() => {
            if (getBotOperatorRes.statusCode == 200) {
                  let botlist = getBotOperatorRes.data.users;

                  const newArray = botlist.filter((item) => {
                        if (item.accountStatus == 1 && item.socialmediaType == "twitter") {
                              return true;
                        }
                        return false;
                  }).map((item) => {
                        return { value: `${item.id}`, label: `${item.accountName}` };
                  });

                  const newPaidArray = botlist.filter((item) => {
                        if (item.accountStatus == 1 && item.socialmediaType == "twitter" && item.accountType == "paid") {
                              return true;
                        }
                        return false;
                  }).map((item) => {
                        return { value: `${item.id}`, label: `${item.accountName}` };
                  });

                  setTwitterPaidBotList(newPaidArray);

                  setTwitterBotList(newArray);

                  setTwitterBotOperatorDetails(botlist);
            }
      }, [getBotOperatorRes]);

      useEffect(() => {
            if (getFbBotOperatorsRes.statusCode == 200) {
                  let botlist = getFbBotOperatorsRes.data.users;

                  const activeBotUsers = botlist.filter((item) => {
                        if (item.accountStatus == 1 && item.socialmediaType == "facebook") {
                              return true;
                        }
                        return false;
                  });

                  let fbPageUsers = {};

                  for (var i in activeBotUsers) {
                        for (var ui in activeBotUsers[i].userPages) {
                              if (fbPageUsers.hasOwnProperty(activeBotUsers[i].userPages[ui].pageId) === false) {

                                    fbPageUsers["" + activeBotUsers[i].userPages[ui].pageId] = [];
                              }
                              fbPageUsers["" + activeBotUsers[i].userPages[ui].pageId].push({ value: `${activeBotUsers[i].id}`, label: `${activeBotUsers[i].accountName}` });
                        }
                  }

                  setFacebookBotList(fbPageUsers);

                  setFacebookBotOperatorDetails(botlist);
            }
      }, [getFbBotOperatorsRes]);

      useEffect(() => {
            console.log(getNewsfeedFailed);
            if (getNewsfeedFailed.statusCode == 401) {
                  dispatch(resetGetNewsFeedFailed());
                  toast.error(getNewsfeedFailed.message, {
                        autoClose: 6000, onClose: () => {
                              dispatch(emptyRedux());
                              logout();
                              navigate("/login");
                        }
                  });
            }
      }, [getNewsfeedFailed]);

      useEffect(() => {

            if (
                  getRecentNewsFeedRes.hasOwnProperty("data") &&
                  getRecentNewsFeedRes.data.hasOwnProperty("newsfeed") &&
                  typeof getRecentNewsFeedRes.data.newsfeed != "undefined"
            ) {
                  let newFeedItems = getRecentNewsFeedRes.data.newsfeed;

                  
                  if (newFeedItems.length > 0) {

                        let initialData = [], botList_operators = [], replyTextInitData = [];

                        for (var i = 0; i < getRecentNewsFeedRes.data.count; i++) {

                              initialData.push(false);
                              replyTextInitData.push('');
                              botList_operators.push([]);
                              if (typeof newFeedItems[i].mediaFiles != "undefined") {
                                    newFeedItems[i].mediaFiles = JSON.parse(newFeedItems[i].mediaFiles);
                              }
                              else {
                                    newFeedItems[i].mediaFiles = [];
                              }

                              if (newFeedItems[i].socialmediaType == "facebook") {
                                    let split_id = newFeedItems[i].newsfeedId.split("_");
                                    newFeedItems[i].page_id = split_id[0];
                              }
                        }

                        setShowmsgbox([
                              ...initialData
                        ]);
                        setLoadingBtnOpenAi([
                              ...initialData
                        ]);
                        setLoadingBtnReply([
                              ...initialData
                        ]);
                        setLoadingBtnRepost([
                              ...initialData
                        ]);
                        setLoadingBtnLike([
                              ...initialData
                        ]);
                        setBotselectors([
                              ...botList_operators
                        ]);
                        setReplyText([
                              ...replyTextInitData
                        ]);

                        setRecentActivities(
                              newFeedItems
                        );
                  }

                  setLoadingNewsfeed(false);

                  dispatch(resetGetRecentNewsFeed());
            }

      }, [getRecentNewsFeedRes]);

      useEffect(() => {

            if (typeof getReplyTweetsRes != "undefined" && getReplyTweetsRes.statusCode == 200) {

                  if (getReplyTweetsRes.data.count > 0) {

                        let recentActivitiesAry = recentActivities;

                        
                   //     let replyActivities = getReplyTweetsRes.data.activities;
                        let replyActivities = getReplyTweetsRes.data.activities.filter((res) => res.botActivity === 'reply-tweet');

                        let recentActi_index = -1;

                        if(replyActivities.length > 0){
                              for (let j in recentActivitiesAry) {
                                    if (recentActivitiesAry[j].newsfeedId == replyActivities[0].newsfeedId) {
                                          recentActi_index = j; break;
                                    }
                              }

                              //To add the reply tweet data to the matched newsfeed item
                              //if (recentActivitiesAry[recentActi_index].hasOwnProperty('replyTweets') === false) {
                                    recentActivitiesAry[recentActi_index].replyTweets = [];
                              //}

                              for (let i in replyActivities) {

                                    //Find the array index for newsfeed payload
                                    recentActivitiesAry[recentActi_index].replyTweets.push(replyActivities[i]);
                              }
                        }

                       
                        setRecentActivities(recentActivitiesAry);
                  }

                  dispatch(resetGetReplyTweets());
            }

      }, [getReplyTweetsRes]);

      useEffect(() => {

            if (typeof getPostCommentsRes != "undefined" && getPostCommentsRes.statusCode == 200) {

                  if (getPostCommentsRes.data.count > 0) {

                        let recentActivitiesAry = recentActivities;

                        let replyActivities = getPostCommentsRes.data.activities;

                        if (replyActivities.length > 0 && typeof replyActivities[0].newsfeedId != "undefined") {

                              //Find the array index for newsfeed payload
                              let recentActi_index = -1;
                              for (let j in recentActivitiesAry) {
                                    if (recentActivitiesAry[j].newsfeedId == replyActivities[0].newsfeedId) {
                                          recentActi_index = j; break;
                                    }
                              }

                              //Empty the existing reply items.
                              recentActivitiesAry[recentActi_index].postComments = [];

                              if (recentActi_index !== -1) for (let i in replyActivities) {

                                    //To add the reply item data to the matched newsfeed item
                                    recentActivitiesAry[recentActi_index].postComments.push(replyActivities[i]);
                              }
                        }

                        setRecentActivities(recentActivitiesAry);
                  }

                  dispatch(resetGetPostComments());
            }

      }, [getPostCommentsRes]);

      useEffect(() => {

            if (typeof openAIRes == "string" && openAIRes != "" && typeof openAIRes_rid != "undefined") { 

                  let get_msg_box = showmsgbox;
                  get_msg_box[openAIRes_rid] = get_msg_box[openAIRes_rid] == true ? false : true;
                  setShowmsgbox([
                        ...get_msg_box
                  ]);

                  let replyTextAry = replyText;

                  replyTextAry[openAIRes_rid] = openAIRes.indexOf("\n") === 0 ? openAIRes.slice(1) : openAIRes;
                  setReplyText([
                        ...replyTextAry
                  ]);
                  dispatch(resetAskOpenAi());
            }

      }, [openAIRes]);

      useEffect(() => {

            if (replyTweetRes.statusCode == 200) {
                  setTimeout(() => {
                        dispatch(resetReplyTweet());
                        let replyTweet_data = {
                              newsfeedId: recentActivities[replyTweetRes_rid].newsfeedId
                        };
      
                        dispatch(getReplyTweets(replyTweet_data));
                        // setFetched(true);
                  }, 2500);

                 

                  toast.success('Successfully posted your reply tweet..!', { autoClose: 3000 });
            }

            let get_msg_box;
            get_msg_box = showmsgbox.map((vlu) => {
                  return false;
            });
            setShowmsgbox([
                  ...get_msg_box
            ]);

            setLoadingBtnOpenAi([
                  ...get_msg_box
            ]);

            let loadingBtnReplyAry = loadingBtnReply;

            loadingBtnReplyAry[replyTweetRes_rid] = false;

            setLoadingBtnReply([
                        ...loadingBtnReplyAry
                  ]);

      }, [replyTweetRes]);

      useEffect(() => {

           // console.log('likeTweetRes');
          //  console.log(likeTweetRes);
            if (likeTweetRes.statusCode == 200) {
                  setTimeout(() => {

                        if(recentActivities !=''){
                        let LikeTweet_data = {
                              newsfeedId: recentActivities[likeTweetRes_rid].newsfeedId
                        };
                        dispatch(getLikeTweets(LikeTweet_data));
                        }
                        dispatch(resetLikeTweet());
                        // setFetched(true);
                  }, 2500);

                  

                  likeTweetRes.data.responses.forEach(response => {
                        if (response.status === 'success') {
                            toast.success(`👍 Success! ${response.bot_id}  has liked the feed.`, { autoClose: 3000 });
                        } else if(response.status === 'failed') {
                            toast.error(`😞 Failed! ${response.bot_id}  couldn't like the feed.`, { autoClose: 3000 });
                        }
                        else if(response.status === 'exists') {
                              toast.error(`😞 Failed! ${response.bot_id} already liked the feed.`, { autoClose: 3000 });
                        }
                    });

                 
                
            }

            let get_like_box;
            get_like_box = showlikebox.map((vlu) => {
                  return false;
            });
            setShowlikebox([
                  ...get_like_box
            ]);



            let loadingBtnLikeAry = loadingBtnLike;

            loadingBtnLikeAry[likeTweetRes_rid] = false;

                  setLoadingBtnLike([
                        ...loadingBtnLikeAry
                  ]);



           
      }, [likeTweetRes]);
      
      useEffect(() => {

            if (typeof getLikeTweetsRes != "undefined" && getLikeTweetsRes.statusCode == 200) {


                 if (getLikeTweetsRes.data.count > 0) {

                        let recentActivitiesAry = recentActivities;

                        let likeActivities = getLikeTweetsRes.data.activities.filter((res) => res.botActivity === 'like-tweet');
                        

                        if (likeActivities.length > 0 && typeof likeActivities[0].newsfeedId != "undefined") {

                              //Find the array index for newsfeed payload
                              let recentActi_index = -1;
                              for (let j in recentActivitiesAry) {
                                    if (recentActivitiesAry[j].newsfeedId == likeActivities[0].newsfeedId) {
                                          recentActi_index = j; break;
                                    }
                              }
                              
                           // if(recentActivitiesAry[recentActi_index].likeTweets != "undefined" ) { 

                              //Empty the existing reply items.
                             // recentActivitiesAry[recentActi_index].likeTweets = [];
                             console.log("Senthil")
                             console.log(recentActivitiesAry);
                            
                             if (recentActi_index !== -1){

                                    if(recentActivitiesAry[recentActi_index].hasOwnProperty("likeTweets")){
                                    recentActivitiesAry[recentActi_index].likeTweets = [];
                                    }
                                    else{
                                          recentActivitiesAry[recentActi_index]["likeTweets"] = [];
                                    }

                                    for (let i in likeActivities) {

                                          //To add the reply item data to the matched newsfeed item
                                          recentActivitiesAry[recentActi_index].likeTweets.push(likeActivities[i]);
                                    }
                              }
                        }

                        setRecentActivities(recentActivitiesAry);
                  
                       //  setReplyTweetList(uniqueDataBasedOnBotAccountId);
                  }

                 dispatch(resetGetLikeTweets());
            }

      }, [getLikeTweetsRes]);

      useEffect(() => {

            
            if (retweetTweetRes.statusCode == 200) {

                  setTimeout(() => {
                  if(recentActivities!=''){      
                  let ReTweet_data = {
                        newsfeedId: recentActivities[retweetTweetRes_rid].newsfeedId
                  }
                  dispatch(getReTweets(ReTweet_data));
                  }
                  

                  dispatch(resetRetweetTweet());               
                        // setFetched(true);
                  }, 2500);

                  retweetTweetRes.data.responses.forEach(response => {
                        if (response.status === 'success') {
                            toast.success(`👍 Success! ${response.bot_id}  has Retweet the feed.`, { autoClose: 3000 });
                        } else if(response.status === 'failed') {
                            toast.error(`😞 Failed! ${response.bot_id}  couldn't Retweet the feed.`, { autoClose: 3000 });
                        }
                        else if(response.status === 'exists') {
                              toast.error(`😞 Failed! ${response.bot_id} already Retweet the feed.`, { autoClose: 3000 });
                        }
                    }); 
                    
                   
                    
            }

            let get_respost_box;
            get_respost_box = showrepostbox.map((vlu) => {
                  return false;
            });
            setShowrepostbox([
                  ...get_respost_box
            ]);

            let loadingBtnRepostAry = loadingBtnRepost;

            loadingBtnRepostAry[retweetTweetRes_rid] = false;

            setLoadingBtnRepost([
                  ...loadingBtnRepostAry
            ]);
                        
           
      }, [retweetTweetRes]);


      useEffect(() => {

            if (typeof getReTweetsRes != "undefined" && getReTweetsRes.statusCode == 200) {

                 
                 if (getReTweetsRes.data.count > 0) {

                        let recentActivitiesAry = recentActivities;

                        let retweetActivities = getReTweetsRes.data.activities.filter((res) => res.botActivity === 'retweet-tweet');

                        console.log("error")
                        console.log(retweetActivities);
                        console.log("end-error")


                        if (retweetActivities.length > 0 && typeof retweetActivities[0].newsfeedId != "undefined") {

                              //Find the array index for newsfeed payload
                              let recentActi_index = -1;
                              for (let j in recentActivitiesAry) {
                                    if (recentActivitiesAry[j].newsfeedId == retweetActivities[0].newsfeedId) {
                                          recentActi_index = j; break;
                                    }
                              }
                            //  console.log(recentActi_index)
                              //Empty the existing reply items.
                              if (recentActi_index !== -1){
                             // recentActivitiesAry[recentActi_index].reTweets = [];

                                        //  if (typeof recentActivitiesAry[recentActi_index].reTweets != "undefined"){
                                                      
                                                            if(recentActivitiesAry[recentActi_index].hasOwnProperty("reTweets")){
                                                            recentActivitiesAry[recentActi_index].reTweets = [];
                                                            }
                                                            else{
                                                                  recentActivitiesAry[recentActi_index]["reTweets"] = [];
                                                            }
                                                
                                                            for (let i in retweetActivities) {
                                                                  console.log(retweetActivities[i])
                                                                  //To add the reply item data to the matched newsfeed item
                                                                  recentActivitiesAry[recentActi_index].reTweets.push(retweetActivities[i]);
                                                            }
                                   // }

                              }



                        }

                        setRecentActivities(recentActivitiesAry);
                  
                  }

                 dispatch(resetGetReTweets());

            }

      }, [getReTweetsRes]);

      useEffect(() => {

            if (typeof commentPostRes != "undefined" && commentPostRes.statusCode == 200) {
                  setTimeout(() => {
                        dispatch(resetCommentPost());
                        // setFetched(true);
                  }, 2500);

                  let replyTweet_data = {
                        newsfeedId: recentActivities[commentPostRes_rid].newsfeedId
                  };

                  dispatch(getPostComments(replyTweet_data));

                  toast.success('Successfully posted your comment..!', { autoClose: 3000 });
            }
            else if (typeof commentPostRes != "undefined" && commentPostRes.statusCode == 400) {
                  setTimeout(() => {
                        dispatch(resetCommentPost());
                        // setFetched(true);
                  }, 2500);

                  toast.success('Something went wrong to post your comment.', { autoClose: 3000 });
            }

            let get_msg_box;
            get_msg_box = showmsgbox.map((vlu) => {
                  return false;
            });

            console.log("+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=");
            console.log(get_msg_box);
            console.log("+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=");

            setShowmsgbox([
                  ...get_msg_box
            ]);

            setLoadingBtnOpenAi([
                  ...get_msg_box
            ]);

      }, [commentPostRes]);

      const handleReplyTextChange = (event, key) => {
            const updatedReplyText = [...replyText];
            updatedReplyText[key] = event.target.value;
        
            setReplyText(updatedReplyText);
        
            const isMaxLengthExceeded = event.target.value.length > maxLength;
        
            setErrorMessage(isMaxLengthExceeded ? `Character limit exceeded! Maximum allowed: ${maxLength}` : '');
        
            const updatedShowerrbox = [...showerrbox];
            updatedShowerrbox[key] = isMaxLengthExceeded;
        
            setShowerrbox(updatedShowerrbox);
        };
        
        const handleTweetTextKeyPress = (event, key) => {
            const val = event.target.value;
            const isMaxLengthExceeded = val.length > maxLength;
        
            if (isMaxLengthExceeded) {
                event.preventDefault();
            }
        
            setErrorMessage(isMaxLengthExceeded ? `Character limit exceeded! Maximum allowed: ${maxLength}` : '');
        
            const updatedShowerrbox = [...showerrbox];
            updatedShowerrbox[key] = isMaxLengthExceeded;
        
            setShowerrbox(updatedShowerrbox);
        };

      const callOpenAi = (newfeedId, msgboxKey) => {

            let newfeedData = recentActivities;

            let prompt_data = {
                  "prompt": "Please suggest the positive reply for this content with in 280 characters: " + newfeedData[msgboxKey].newsfeedContent
            };

            dispatch(askOpenAi(prompt_data, msgboxKey));

            let loadingBtnOpenAiAry = loadingBtnOpenAi;

            loadingBtnOpenAiAry[msgboxKey] = true;

            setLoadingBtnOpenAi([
                  ...loadingBtnOpenAiAry
            ]);
      }

      const twittLike = (newfeedId, likeboxKey) => {

            let get_like_box = showlikebox;
            get_like_box[likeboxKey] = get_like_box[likeboxKey] == true ? false : true;
            setShowlikebox([
                  ...get_like_box
            ]);

            if (showrepostbox[likeboxKey]) {
                  twittRepost(newfeedId, likeboxKey)
            }

      }


      const twittRepost = (newfeedId, repostboxKey) => {

            let get_repost_box = showrepostbox;
            get_repost_box[repostboxKey] = get_repost_box[repostboxKey] == true ? false : true;
            setShowrepostbox([
                  ...get_repost_box
            ]);

            if (showlikebox[repostboxKey]) {
                  twittLike(newfeedId, repostboxKey)
            }

      }



      const handleBotOperatorChanges = (key, options) => {
            let bot_ids = [];

            for (var i = 0, l = options.length; i < l; i++) {
                  bot_ids.push(options[i].value);
            }
             
            let botselectorsAry = botselectors;
            botselectorsAry[key] = bot_ids;
            setBotselectors([
                  ...botselectorsAry
            ]);
      }

      const submitReply = (newfeedId, msgboxKey, mediaType) => {


            if (replyText[msgboxKey].trim() != "") {
                  if (botselectors[msgboxKey].length > 0) {


                        if (replyText[msgboxKey].length > 280) {
                              toast.error("Character limit exceeded! Maximum allowed: 280", {autoClose:5000});
                              return; 
                        }

                        if (mediaType == "twitter") {
                              botselectors[msgboxKey].map((item) => {
                                    let reply_data = {
                                          "reply_tweet": replyText[msgboxKey],
                                          "tweet_id": newfeedId,
                                          "bot_id": parseInt(item)
                                    }

                                    dispatch(replyTweet(reply_data, msgboxKey));
                              });
                        }
                        else if (mediaType == "facebook") {
                              botselectors[msgboxKey].map((item) => {
                                    let reply_data = {
                                          "message": replyText[msgboxKey],
                                          "post_id": newfeedId,
                                          "bot_id": parseInt(item)
                                    }

                                    console.log("+++++++++++++++");
                                    console.log(reply_data);
                                    console.log("+++++++++++++++");

                                    dispatch(commentPost(reply_data, msgboxKey));
                              });
                        }

                        let replyTextAry = replyText;
                        replyTextAry[msgboxKey] = "";
                        setReplyText([
                              ...replyTextAry
                        ]);

                        let loadingBtnReplyAry = loadingBtnReply;

                        loadingBtnReplyAry[msgboxKey] = true;

                        setLoadingBtnReply([
                              ...loadingBtnReplyAry
                        ]);

                  }
                  else {
                        toast.error('Please select the users..!', { autoClose: 3000 });
                  }

            }
            else {
                  toast.error('invalid or empty value given. please try again!', { autoClose: 3000 });
            }
      }



      const submitRepost = (newfeedId, msgboxKey, mediaType) => {
         
            if (botselectors[msgboxKey].length > 0) {
                  if (mediaType == "twitter") {
                        let reply_data = {
                              "tweet_id": newfeedId,
                              "bot_id": botselectors[msgboxKey]
                        }
                        dispatch(retweetTweet(reply_data, msgboxKey));
                  }

                  
                  // else if(mediaType == "facebook"){
                  //       botselectors[msgboxKey].map((item) => {
                  //             let reply_data = {
                  //                   "message": replyText[msgboxKey],
                  //                   "post_id": newfeedId,
                  //                   "bot_id": parseInt(item)
                  //             }

                  //             console.log("+++++++++++++++");
                  //             console.log(reply_data);
                  //             console.log("+++++++++++++++");

                  //             dispatch(commentPost(reply_data, msgboxKey));
                  //       });
                  // }

                  // let replyTextAry = replyText;
                  // replyTextAry[msgboxKey] = "";
                  // setReplyText([
                  //       ...replyTextAry
                  // ]);

                  let loadingBtnRepostAry = loadingBtnRepost;

                  loadingBtnRepostAry[msgboxKey] = true;

                  setLoadingBtnRepost([
                        ...loadingBtnRepostAry
                  ]);

                  
            }
            else {
                  toast.error('Please select the users..!', { autoClose: 3000 });
            }
      }


      const submitLike = (newfeedId, msgboxKey, mediaType) => {

            if (botselectors[msgboxKey].length > 0) {
                  console.log("Bot",botselectors[msgboxKey])
                  if (mediaType == "twitter") {
                        let reply_data = {
                              "tweet_id": newfeedId,
                              "bot_id": botselectors[msgboxKey]
                        }
                        dispatch(likeTweet(reply_data, msgboxKey));
                  }
                  
                  
                  // else if(mediaType == "facebook"){
                  //       botselectors[msgboxKey].map((item) => {
                  //             let reply_data = {
                  //                   "message": replyText[msgboxKey],
                  //                   "post_id": newfeedId,
                  //                   "bot_id": parseInt(item)
                  //             }

                  //             console.log("+++++++++++++++");
                  //             console.log(reply_data);
                  //             console.log("+++++++++++++++");

                  //             dispatch(commentPost(reply_data, msgboxKey));
                  //       });
                  // }

                  // let replyTextAry = replyText;
                  // replyTextAry[msgboxKey] = "";
                  // setReplyText([
                  //       ...replyTextAry
                  // ]);

                  let loadingBtnLikeAry = loadingBtnLike;

                  loadingBtnLikeAry[msgboxKey] = true;

                  setLoadingBtnLike([
                        ...loadingBtnLikeAry
                  ]);

                  
            }
            else {
                  toast.error('Please select the users..!', { autoClose: 3000 });
            }

            
      }


      const animatedComponents = makeAnimated();

      return (
            <div className="dashboard">
                  <header>
                        <div className="flex">
                              <div className="lft ptflex">
                                    <h3>Live Feeds </h3>
                                    {lastCronRunTime ?
                                          <span className='small text-white'>[Last sync {dayjs(lastCronRunTime).fromNow()}]</span>
                                          : ""
                                    }
                              </div>
                              <HeaderProfileArea />
                        </div>
                  </header>
                  <div className="main_content innermain">
                        <div className="rec_box recentactivity fadein">
                              <div>
                                    {
                                          loadingNewsfeed === true
                                                ?

                                                <Loader></Loader>

                                                :
                                                (
                                                      recentActivities.length === 0
                                                            ?
                                                            <p className="text-center">No posts available in the past week</p>
                                                            :
                                                            recentActivities.map((value, key) => {

                                                                  return (
                                                                        <div key={key} className={"boxs " + (value.socialmediaType == "twitter" ? "_twt" : (value.socialmediaType == "facebook" ? "_fb" : "_insta"))} >
                                                                              {/* <div className='d-close'><img src={DClose} alt="" /></div> */}
                                                                              <div className='d-close extlinks'>
                                                                                    <a href={'https://twitter.com/' + value.userName + '/status/' + value.newsfeedId} target='_blank'>
                                                                                          <img src={ExtLink} alt="" />
                                                                                    </a>
                                                                              </div>
                                                                              <div className="meida_icons">
                                                                                    <img src={value.socialmediaType == "twitter" ? Twitter_circle : (value.socialmediaType == "facebook" ? Facebook_circle : Instagram_circle)} alt="" />
                                                                              </div>
                                                                              <div className="media_txt">
                                                                                    <div className="icon">
                                                                                          <img src={User} alt="" />
                                                                                    </div>
                                                                                    <div className="_txt">
                                                                                          <h4>{value.userName}</h4>
                                                                                          <span>{dayjs(value.createdAt).fromNow()}</span>
                                                                                          <pre className='textoverflowfix'>
                                                                                                <p>{value.newsfeedContent}</p>

                                                                                                <div className={'post_img' + (value.mediaFiles.length === 0 ? 'd-none' : '')}>
                                                                                                      <ul>
                                                                                                            {value.mediaFiles.length > 0 ? value.mediaFiles.map((file_data, file_key) => {

                                                                                                                  let media_key = file_data.media_key;

                                                                                                                  let media_url = "";

                                                                                                                  if (file_data.type === "photo") {
                                                                                                                        media_url = file_data.url;
                                                                                                                  }
                                                                                                                  else if (file_data.type === "video") {

                                                                                                                        for (let vi in file_data.variants) {
                                                                                                                              if (file_data.variants[vi].content_type === "video/mp4" && file_data.variants[vi].bit_rate === 2176000) {
                                                                                                                                    media_url = file_data.variants[vi].url; break;
                                                                                                                              }
                                                                                                                        }
                                                                                                                  }
                                                                                                                  else if (file_data.type === "animated_gif") {
                                                                                                                        media_url = file_data.variants[0].url;
                                                                                                                  }

                                                                                                                  return (
                                                                                                                        <li>
                                                                                                                              <a href={media_url} target="_blank">
                                                                                                                                    {media_url != "" ? (
                                                                                                                                          file_data.type === "video" || file_data.type === "animated_gif"
                                                                                                                                                ?
                                                                                                                                                <video controls >
                                                                                                                                                      <source src={media_url} type="video/mp4" />
                                                                                                                                                </video>
                                                                                                                                                :
                                                                                                                                                <img src={media_url} alt={media_key} />
                                                                                                                                    )
                                                                                                                                          : null
                                                                                                                                    }
                                                                                                                              </a>
                                                                                                                        </li>
                                                                                                                  );
                                                                                                            })
                                                                                                                  : ""
                                                                                                            }

                                                                                                      </ul>
                                                                                                </div>


                                                                                          </pre>
                                                                                          {/*<ul>
                                                                        <li>#Lorem</li>
                                                                        <li>@ipsum</li>
                                                                        </ul>*/}

                                                                                          {typeof recentActivities[key].replyTweets != "undefined" ? recentActivities[key].replyTweets.map((r_twt, rt_key) => {
                                                                                                return (
                                                                                                      <div key={rt_key + 1} className="msg_box " >
                                                                                                            <div className="media_txt mb-2 ms-0 bg-white w-100">
                                                                                                                  <div className="_txt retwit">
                                                                                                                        <div className='retwituser'>
                                                                                                                              <h4>{r_twt.botUsername}</h4>
                                                                                                                              <span>{dayjs(r_twt.createdAt).fromNow()}</span>
                                                                                                                        </div>
                                                                                                                        <div className="msg_box" >
                                                                                                                              <p className='m-0'>{r_twt.replyContent}</p>
                                                                                                                        </div>
                                                                                                                  </div>
                                                                                                            </div>

                                                                                                      </div>
                                                                                                )
                                                                                          }) : null}

                                                                                          {typeof recentActivities[key].postComments != "undefined" ? recentActivities[key].postComments.map((r_twt, rt_key) => {
                                                                                                return (
                                                                                                      <div key={rt_key + 1} className="msg_box " >
                                                                                                            <div className="media_txt mb-2 ms-0 bg-white w-100">
                                                                                                                  <div className="_txt retwit">
                                                                                                                        <div className='retwituser'>
                                                                                                                              <h4>{r_twt.botUsername}</h4>
                                                                                                                              <span>{dayjs(r_twt.createdAt).fromNow()}</span>
                                                                                                                        </div>
                                                                                                                        <div className="msg_box" >
                                                                                                                              <p className='m-0'>{r_twt.replyContent}</p>
                                                                                                                        </div>
                                                                                                                  </div>
                                                                                                            </div>

                                                                                                      </div>
                                                                                                )
                                                                                          }) : null}

                                                                                          <button type='button' className={'gpt_btn ' + (showmsgbox[key] ? "d-none " : "") + (loadingBtnOpenAi[key] ? "btn_loading" : "")} onClick={() => callOpenAi(value.id, key)}>
                                                                                                <img src={Chatgpt} alt="" />
                                                                                                <span> Generate response from chatgpt</span>
                                                                                                <ButtonLoading />
                                                                                          </button>

                                                                                          <div className={"msg_box " + (showmsgbox[key] ? "" : "d-none")} >
                                                                                          <div className={"errbox " + (showerrbox[key] ? "" : "d-none")}>{errorMessage}</div>
                                                                                                <div className="show_msg">
                                                                                                      <textarea
                                                                                                            id={"replyText" + key}
                                                                                                            name={"replyText" + key}
                                                                                                            value={replyText[key]}
                                                                                                            onChange={(e) => handleReplyTextChange(e, key)}
                                                                                                            className="form-control replytextarea"
                                                                                                            onKeyPress={(e) => handleTweetTextKeyPress(e, key)}
                                                                                                      />
                                                                                                </div>
                                                                                                <div className="type_box">
                                                                                                      <Select
                                                                                                            closeMenuOnSelect={false}
                                                                                                            components={animatedComponents}
                                                                                                            isMulti
                                                                                                            options={value.socialmediaType == "twitter" ? twitterBotList : facebookBotList["" + value.page_id]}
                                                                                                            onChange={(e) => handleBotOperatorChanges(key, e)}
                                                                                                      />
                                                                                                      <button className={'send_btn ' + (loadingBtnReply[key] ? "btn_loading" : "")} type='button' onClick={() => submitReply(value.newsfeedId, key, value.socialmediaType)} >
                                                                                                            <span> <img src={Send} alt="" /></span>
                                                                                                            <ButtonLoading />
                                                                                                      </button>
                                                                                                </div>
                                                                                          </div>

                                                                                          <div className='like_repost_box'>
                                                                                                <div className="likebox_panel" >
                                                                                                      <h4>Like</h4>
                                                                                                      <button type='button' className={'like_btn ' + (showlikebox[key] ? " " : "") + (loadingBtnOpenAi[key] ? "btn_loading" : "")} onClick={() => twittLike(value.id, key)}>
                                                                                                            <img src={Tweetlikebtn} alt="" />
                                                                                                            <span>Send Like</span>
                                                                                                      </button>
                                                                                                      <div className={"type_box " + (showlikebox[key] ? "" : "d-none")} >
                                                                                                            <Select
                                                                                                                  closeMenuOnSelect={false}
                                                                                                                  components={animatedComponents}
                                                                                                                  isMulti
                                                                                                                  options={value.socialmediaType == "twitter" ? twitterPaidBotList : facebookBotList["" + value.page_id]}
                                                                                                                  onChange={(e) => handleBotOperatorChanges(key, e)}
                                                                                                            />
                                                                                                            <button className={'send_btn ' + (loadingBtnLike[key] ? "btn_loading" : "")} type='button' onClick={() => submitLike(value.newsfeedId, key, value.socialmediaType)} >
                                                                                                                  <span> <img src={Send} alt="" /></span>
                                                                                                                  <ButtonLoading />
                                                                                                            </button>
                                                                                                      </div>

                                                                                                      
                                                                                    
                                                                                                      <div className='recent_likes'>
                                                                                                            <span className="title">
                                                                                                                  <span>Recent Likes:</span>
                                                                                                            </span>
                                                                                                            <ul>
                                                                                                                  {typeof recentActivities[key].likeTweets != "undefined" ? recentActivities[key].likeTweets.map((r_twt, rt_key) => {
                                                                                                                  return (
                                                                                                                        <li key={rt_key + 1}>
                                                                                                                        <img src={User} alt="" />
                                                                                                                        <span>{r_twt.botUsername}</span>
                                                                                                                         </li>   

                                                                                                                  )
                                                                                                            }) : <li>
                                                                                                            <span>Not yet Likes</span>
                                                                                                            </li>}
                                                                                                          </ul>
                                                                                                      </div>

                                                                                                </div>



                                                                                                <div className='repostbox_panel'>
                                                                                                      <h4>ReTweet</h4>
                                                                                                      <button type='button' className={'like_btn ' + (showrepostbox[key] ? " " : "") + (loadingBtnOpenAi[key] ? "btn_loading" : "")} onClick={() => twittRepost(value.id, key)}>
                                                                                                            <img src={Tweet_repost_icon} alt="" />
                                                                                                            <span>Retweet</span>
                                                                                                      </button>
                                                                                                      <div className={"type_box " + (showrepostbox[key] ? "" : "d-none")} >
                                                                                                            <Select
                                                                                                                  closeMenuOnSelect={false}
                                                                                                                  components={animatedComponents}
                                                                                                                  isMulti
                                                                                                                  options={value.socialmediaType == "twitter" ? twitterPaidBotList : facebookBotList["" + value.page_id]}
                                                                                                                  onChange={(e) => handleBotOperatorChanges(key, e)}
                                                                                                            />
                                                                                                            <button className={'send_btn ' + (loadingBtnRepost[key] ? "btn_loading" : "")} type='button' onClick={() => submitRepost(value.newsfeedId, key, value.socialmediaType)} >
                                                                                                                  <span> <img src={Send} alt="" /></span>
                                                                                                                  <ButtonLoading />
                                                                                                            </button>
                                                                                                      </div>
                                                                                                      <div className='recent_likes'>
                                                                                                            <span className="title">
                                                                                                                  <span>Recent Retweets:</span>
                                                                                                            </span>
                                                                                                            <ul>
                                                      

                                                                                                                  {typeof recentActivities[key].reTweets != "undefined" ? recentActivities[key].reTweets.map((r_twt, rt_key) => {
                                                                                                                  return (
                                                                                                                        <li key={rt_key + 1}>
                                                                                                                        <img src={User} alt="" />
                                                                                                                        <span>{r_twt.botUsername}</span>
                                                                                                                         </li>   

                                                                                                                  )
                                                                                                                  }) : <li>
                                                                                                                   <span>Not yet Retweet</span>
                                                                                                                   </li>  
                                                                                                                  }
                                                                                                            </ul>
                                                                                                      </div>
                                                                                                </div>

                                                                                          </div>



                                                                                    </div>


                                                                              </div>
                                                                        </div>
                                                                  );
                                                            })
                                                )
                                    }
                              </div>
                        </div>
                  </div>
                  <ToastContainer />
            </div>
      )
}



export default RecentActivity;