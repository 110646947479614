import api from '../services/api';
import apiWithJwt from '../services/apiWithJwt';
import { types } from '../store/constants/types';


export const createTweet = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.post('/twitter/create-tweet', data)
      .then((response) => {
        dispatch({ type: types.CREATE_TWEET_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.CREATE_TWEET_FAILED, payload: (error.response.data)});
      });
  };
};
export const resetCreateTweet = () => {
  return dispatch => {
     dispatch({type : types.CREATE_TWEET_SUCCESS,payload : []})
}}

export const getBotOperators = () => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.get('/twitter/bot-operator-all')
      .then((response) => {
        dispatch({ type: types.GET_BOT_OPERATOR_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_BOT_OPERATOR_FAILED, payload: (error.response.data)});
      });
  };
};
export const resetGetBotOperators = () => {
  return dispatch => {
     dispatch({type : types.GET_BOT_OPERATOR_SUCCESS,payload : []})
}}

export const getActiveBotOperators = () => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.get('/twitter/active-bot-operator-all')
      .then((response) => {
        dispatch({ type: types.GET_ACTIVE_BOT_OPERATOR_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_ACTIVE_BOT_OPERATOR_FAILED, payload: (error.response.data)});
      });
  };
};
export const resetActiveBotOperatorsFailed = () => {
  return dispatch => {
    dispatch({type : types.GET_ACTIVE_BOT_OPERATOR_FAILED,payload : []})
  }
}
export const resetActiveBotOperators = () => {
  return dispatch => {
     dispatch({type : types.GET_ACTIVE_BOT_OPERATOR_SUCCESS,payload : []})
}}

export const getAllNewsFeed = () => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.get('/twitter/newsfeed-all')
      .then((response) => {
        dispatch({ type: types.GET_NEWSFEED_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_NEWSFEED_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetGetAllNewsFeed = () => {
  return dispatch => {
     dispatch({type : types.GET_NEWSFEED_SUCCESS,payload : []})
}}

export const resetGetNewsFeedFailed = () => {
  return dispatch => {
     dispatch({type : types.GET_NEWSFEED_FAILED,payload : []})
}}

export const logIn = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.post('/user/login',data)
      .then((response) => {
        dispatch({ type: types.GET_LOGIN_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_LOGIN_FAILED, payload: (error.response.data)});
      });
  };
};
export const resetLogIn = () => {
  return dispatch => {
     dispatch({type : types.GET_LOGIN_SUCCESS,payload : []})
}}

export const createBotOperator = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.post('/twitter/create-bot-operator', data)
      .then((response) => {
        dispatch({ type: types.CREATE_BOT_OPERATOR_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.CREATE_BOT_OPERATOR_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetcreateBotOperator = () => {
  return dispatch => {
     dispatch({type : types.CREATE_BOT_OPERATOR_SUCCESS,payload : []})
}}

export const updateBotOperator = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.put('/twitter/update-bot-operator', data)
      .then((response) => {
        dispatch({ type: types.UPDATE_BOT_OPERATOR_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.UPDATE_BOT_OPERATOR_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetUpdateBotOperator = () => {
  return dispatch => {
     dispatch({type : types.UPDATE_BOT_OPERATOR_SUCCESS,payload : []})
}}

export const updateBotOperatorStatus = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.put('/twitter/update-bot-operator-status/'+data.bot_id+'/'+data.accountStatus)
      .then((response) => {
        dispatch({ type: types.UPDATE_BOT_OPERATOR_STATUS_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.UPDATE_BOT_OPERATOR_STATUS_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetUpdateBotOperatorStatus = () => {
  return dispatch => {
     dispatch({type : types.UPDATE_BOT_OPERATOR_STATUS_SUCCESS,payload : []})
}}

export const replyTweet = (data, rid) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.post('/twitter/reply-tweet', data)
      .then((response) => {
        dispatch({ type: types.REPLY_TWEET_SUCCESS, payload: response.data, rid: rid });
      })
      .catch((error) => {
        dispatch({ type: types.REPLY_TWEET_FAILED, payload: (error.response.data), rid: rid});
      });
  };
};
export const resetReplyTweet = () => {
  return dispatch => {
     dispatch({type : types.REPLY_TWEET_SUCCESS,payload : [], rid: -1})
}}


export const getReplyTweets = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.post('/twitter/bot-operator-activity-by-newsfeedId', data)
      .then((response) => {
        dispatch({ type: types.GET_REPLY_TWEET_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_REPLY_TWEET_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetGetReplyTweets = () => {
  return dispatch => {
     dispatch({type : types.GET_REPLY_TWEET_SUCCESS, payload : []})
}}

export const createTargetAccount = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.post('/twitter/create-target-account', data)
      .then((response) => {
        dispatch({ type: types.CREATE_TARGET_ACCOUNT_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.CREATE_TARGET_ACCOUNT_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetCreateTargetAccount = () => {
  return dispatch => {
     dispatch({type : types.CREATE_TARGET_ACCOUNT_SUCCESS, payload : []})
}}

export const resetCreateTargetAccountFailed = () => {
  return dispatch => {
     dispatch({type : types.CREATE_TARGET_ACCOUNT_FAILED, payload : []})
}}

export const getTargetAccounts = () => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.get('/twitter/target-account-all')
      .then((response) => {
        dispatch({ type: types.GET_TARGET_ACCOUNT_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_TARGET_ACCOUNT_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetGetTargetAccounts = () => {
  return dispatch => {
     dispatch({type : types.GET_TARGET_ACCOUNT_SUCCESS,payload : []})
}}

export const resetGetTargetAccountsFailed = () => {
  return dispatch => {
     dispatch({type : types.GET_TARGET_ACCOUNT_FAILED,payload : []})
}}

export const updateTargetAccountStatus = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.put('/twitter/update-target-account-status/'+data.targetaccount_id+'/'+data.accountStatus)
      .then((response) => {
        dispatch({ type: types.UPDATE_TARGET_ACCOUNT_STATUS_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.UPDATE_TARGET_ACCOUNT_STATUS_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetUpdateTargetAccountStatus = () => {
  return dispatch => {
     dispatch({type : types.UPDATE_TARGET_ACCOUNT_STATUS_SUCCESS,payload : []})
}}


export const deleteTargetAccount = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.delete('/twitter/delete-target-account/', data)
      .then((response) => {
        dispatch({ type: types.DELETE_TARGET_ACCOUNT_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.DELETE_TARGET_ACCOUNT_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetDeleteTargetAccount = () => {
  return dispatch => {
     dispatch({type : types.DELETE_TARGET_ACCOUNT_SUCCESS, payload : []})
}}


export const createHashtag = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.post('/twitter/create-hashtag', data)
      .then((response) => {
        dispatch({ type: types.CREATE_HASHTAG_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.CREATE_HASHTAG_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetCreateHashtag = () => {
  return dispatch => {
     dispatch({type : types.CREATE_HASHTAG_SUCCESS, payload : []})
}}

export const getHashtag = () => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.get('/twitter/hashtag-all')
      .then((response) => {
        dispatch({ type: types.GET_HASHTAG_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_HASHTAG_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetGetHashtag = () => {
  return dispatch => {
     dispatch({type : types.GET_HASHTAG_SUCCESS,payload : []})
}}

export const updateHashtagStatus = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.put('/twitter/update-hashtag-status/'+data.hashtag_id+'/'+data.hashtagStatus)
      .then((response) => {
        dispatch({ type: types.UPDATE_HASHTAG_STATUS_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.UPDATE_HASHTAG_STATUS_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetUpdateHashtagStatus = () => {
  return dispatch => {
     dispatch({type : types.UPDATE_HASHTAG_STATUS_SUCCESS,payload : []})
}}

export const deleteHashtag = (data) => {
  console.log("delete req", data);
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.delete('/twitter/delete-hashtag/', data)
      .then((response) => {
        dispatch({ type: types.DELETE_HASHTAG_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.DELETE_HASHTAG_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetDeleteHashtag = () => {
  return dispatch => {
     dispatch({type : types.DELETE_HASHTAG_SUCCESS, payload : []})
}}

export const deleteBotOperator = (data) => {
  console.log("delete req", data);
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.delete('/twitter/delete-bot-operator/', data)
      .then((response) => {
        dispatch({ type: types.DELETE_BOT_OPERATOR_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.DELETE_BOT_OPERATOR_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetDeleteBotOperator = () => {
  return dispatch => {
     dispatch({type : types.DELETE_BOT_OPERATOR_SUCCESS, payload : []})
}}

export const getAllPostHistory = () => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.get('/twitter/post-history-all')
      .then((response) => {
        dispatch({ type: types.GET_POST_HISTORY_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_POST_HISTORY_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetGetAllPostHistory = () => {
  return dispatch => {
     dispatch({type : types.GET_POST_HISTORY_SUCCESS,payload : []})
}}

export const getAllCronLogs = () => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.get('/twitter/cron-logs-all')
      .then((response) => {
        dispatch({ type: types.GET_CRON_LOGS_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_CRON_LOGS_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetGetAllCronLogs = () => {
  return dispatch => {
     dispatch({type : types.GET_CRON_LOGS_SUCCESS,payload : []})
}}

export const getCronLogsCount = () => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.get('/twitter/cron-logs-count')
      .then((response) => {
        dispatch({ type: types.GET_CRON_LOGS_COUNT_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_CRON_LOGS_COUNT_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetGetCronLogsCount = () => {
  return dispatch => {
     dispatch({type : types.GET_CRON_LOGS_COUNT_SUCCESS,payload : []})
}}

export const emptyRedux = () => {
  return dispatch => {
    dispatch({type : types.GET_ACTIVE_BOT_OPERATOR_FAILED, payload : []})
    dispatch({type : types.GET_BOT_OPERATOR_FAILED, payload : []})
    dispatch({type : types.CREATE_TWEET_FAILED, payload : []})
    dispatch({type : types.CREATE_TARGET_ACCOUNT_FAILED, payload : []})
    dispatch({type : types.GET_TARGET_ACCOUNT_FAILED, payload : []})
    dispatch({type : types.GET_NEWSFEED_FAILED, payload : []})
  }
}

export const getRecentNewsFeed = () => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.get('/twitter/newsfeed-recent')
      .then((response) => {
        dispatch({ type: types.GET_RECENT_NEWSFEED_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_RECENT_NEWSFEED_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetGetRecentNewsFeed = () => {
  return dispatch => {
     dispatch({type : types.GET_RECENT_NEWSFEED_SUCCESS,payload : []})
}}

export const getLastCronRunTime = () => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.get('/twitter/last-cron-run-time')
      .then((response) => {
        dispatch({ type: types.GET_LAST_CRON_RUN_TIME_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_LAST_CRON_RUN_TIME_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetGetLastCronRunTime = () => {
  return dispatch => {
     dispatch({type : types.GET_LAST_CRON_RUN_TIME_SUCCESS,payload : []})
}}

export const likeTweet = (data, rid) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.post('/twitter/like-tweet', data)
      .then((response) => {
        dispatch({ type: types.LIKE_TWEET_SUCCESS, payload: response.data, rid: rid });
      })
      .catch((error) => {
        dispatch({ type: types.LIKE_TWEET_FAILED, payload: (error.response.data), rid: rid});
      });
  };
};
export const resetLikeTweet = () => { 
  return dispatch => {
     dispatch({type : types.LIKE_TWEET_SUCCESS,payload : [], rid: -1})
}}


export const getLikeTweets = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.post('/twitter/bot-operator-activity-by-newsfeedId', data)
      .then((response) => {
        dispatch({ type: types.GET_LIKE_TWEET_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_LIKE_TWEET_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetGetLikeTweets = () => {
  return dispatch => {
     dispatch({type : types.GET_LIKE_TWEET_SUCCESS, payload : []})
}}

export const retweetTweet = (data, rid) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.post('/twitter/retweet-tweet', data)
      .then((response) => {
        dispatch({ type: types.RETWEET_TWEET_SUCCESS, payload: response.data, rid: rid });
      })
      .catch((error) => {
        dispatch({ type: types.RETWEET_TWEET_FAILED, payload: (error.response.data), rid: rid});
      });
  };
};

export const resetRetweetTweet = () => { 
  return dispatch => {
     dispatch({type : types.RETWEET_TWEET_SUCCESS,payload : [], rid: -1})
}}

export const getReTweets = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.post('/twitter/bot-operator-activity-by-newsfeedId', data)
      .then((response) => {
        dispatch({ type: types.GET_RETWEET_TWEET_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_RETWEET_TWEET_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetGetReTweets = () => {
  return dispatch => {
     dispatch({type : types.GET_RETWEET_TWEET_SUCCESS, payload : []})
}}