import { types } from "../constants/types";

const initialState = {
    getFacebookPages: [],
    getFacebookPagesFailed: '',
    getUserPages: [],
    getUserPagesFailed: '',
    updateFacebookPageStatus: [],
    updateFacebookPageStatusFailed: '',
    deleteFacebookPage: [],
    deleteFacebookPageFailed: '',
    createPost: [],
    createPostFailed: '',
    getRedirectUrl: [],
    getRedirectUrlFailed: '',
    getAppKeys: [],
    getAppKeysFailed: '',
    commentPost: [],
    commentPostFailed: '',
    commentPostRid: '',
    commentPostRidFailed: '',
    getPostComments: [],
    getPostCommentsFailed: '',
    getFbBotOperators: [],
    getFbBotOperatorsFailed: ''
}
//receives the current state and an action object
export function facebookReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_FACEBOOK_PAGE_SUCCESS:
            return {
                ...state,
                getFacebookPages : action.payload
            }
        case types.GET_FACEBOOK_PAGE_FAILED:
            return {
                ...state,
                getFacebookPagesFailed : action.payload
            }
        case types.GET_FACEBOOK_USER_PAGES_SUCCESS:
            return {
                ...state,
                getUserPages : action.payload
            }
        case types.GET_FACEBOOK_USER_PAGES_FAILED:
            return {
                ...state,
                getUserPagesFailed : action.payload
            }
        case types.UPDATE_FACEBOOK_PAGE_STATUS_SUCCESS:
            return {
                ...state,
                updateFacebookPageStatus : action.payload
            }
        case types.UPDATE_FACEBOOK_PAGE_STATUS_FAILED:
            return {
                ...state,
                updateFacebookPageStatusFailed : action.payload
            }
        case types.DELETE_FACEBOOK_PAGE_SUCCESS:
            return {
                ...state,
                deleteFacebookPage : action.payload
            }
        case types.DELETE_FACEBOOK_PAGE_FAILED:
            return {
                ...state,
                deleteFacebookPageFailed : action.payload
            }
        case types.CREATE_FACEBOOK_PAGE_POST_SUCCESS:
            return {
                ...state,
                createPost : action.payload
            }
        case types.CREATE_FACEBOOK_PAGE_POST_FAILED:
            return {
                ...state,
                createPostFailed : action.payload
            }
        case types.GET_FACEBOOK_REDIRECT_URL_SUCCESS:
            return {
                ...state,
                getRedirectUrl : action.payload
            }
        case types.GET_FACEBOOK_REDIRECT_URL_FAILED:
            return {
                ...state,
                getRedirectUrlFailed : action.payload
            } 
        case types.GET_FACEBOOK_APP_KEYS_SUCCESS:
            return {
                ...state,
                getAppKeys : action.payload
            }
        case types.GET_FACEBOOK_APP_KEYS_FAILED:
            return {
                ...state,
                getAppKeysFailed : action.payload
            } 
        case types.COMMENT_FACEBOOK_PAGE_POST_SUCCESS:
            return {
                ...state,
                commentPost : action.payload,
                commentPostRid : action.rid
            }
        case types.COMMENT_FACEBOOK_PAGE_POST_FAILED:
            return {
                ...state,
                commentPostFailed : action.payload,
                commentPostRidFailed : action.rid
            }
        case types.GET_POST_COMMENT_SUCCESS:
            return {
                ...state,
                getPostComments : action.payload
            }
        case types.GET_POST_COMMENT_FAILED:
            return {
                ...state,
                getPostCommentsFailed : action.payload
            }
        case types.GET_FB_BOT_OPERATOR_SUCCESS:
            return {
                ...state,
                getFbBotOperators : action.payload
            }
        case types.GET_FB_BOT_OPERATOR_FAILED:
            return {
                ...state,
                getFbBotOperatorsFailed : action.payload
            }
            
        default:
            return state
    }
}