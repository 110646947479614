import api from '../services/api';
import apiWithJwt from '../services/apiWithJwt';
import { types } from '../store/constants/types';

export const getFbBotOperators = () => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.get('/facebook/bot-operator-all')
      .then((response) => {
        dispatch({ type: types.GET_FB_BOT_OPERATOR_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_FB_BOT_OPERATOR_FAILED, payload: (error.response.data)});
      });
  };
};
export const resetGetFbBotOperators = () => {
  return dispatch => {
     dispatch({type : types.GET_FB_BOT_OPERATOR_SUCCESS,payload : []})
}}

export const getPages = () => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.get('/facebook/facebook-page-all')
      .then((response) => {
        dispatch({ type: types.GET_FACEBOOK_PAGE_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_FACEBOOK_PAGE_FAILED, payload: (error.response.data)});
      });
  };
};
export const resetGetPages = () => {
  return dispatch => {
     dispatch({type : types.GET_FACEBOOK_PAGE_SUCCESS,payload : []})
}}

export const getUserPages = () => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.get('/facebook/user-page-all')
      .then((response) => {
        dispatch({ type: types.GET_FACEBOOK_USER_PAGES_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_FACEBOOK_USER_PAGES_FAILED, payload: (error.response.data)});
      });
  };
};
export const resetGetUserPages = () => {
  return dispatch => {
     dispatch({type : types.GET_FACEBOOK_USER_PAGES_SUCCESS,payload : []})
}}

export const updateFacebookPageStatus = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.put('/facebook/update-facebook-page-status/'+data.page_id+'/'+data.pageStatus)
      .then((response) => {
        dispatch({ type: types.UPDATE_FACEBOOK_PAGE_STATUS_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.UPDATE_FACEBOOK_PAGE_STATUS_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetUpdateFacebookPageStatus = () => {
  return dispatch => {
     dispatch({type : types.UPDATE_FACEBOOK_PAGE_STATUS_SUCCESS,payload : []})
}}

export const deleteFacebookPage = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.delete('/facebook/delete-facebook-page/', data)
      .then((response) => {
        dispatch({ type: types.DELETE_FACEBOOK_PAGE_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.DELETE_FACEBOOK_PAGE_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetDeleteFacebookPage = () => {
  return dispatch => {
     dispatch({type : types.DELETE_FACEBOOK_PAGE_SUCCESS, payload : []})
}}

export const createPost = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.post('/facebook/create-post/', data)
      .then((response) => {
        dispatch({ type: types.CREATE_FACEBOOK_PAGE_POST_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.CREATE_FACEBOOK_PAGE_POST_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetCreatePost = () => {
  return dispatch => {
     dispatch({type : types.CREATE_FACEBOOK_PAGE_POST_SUCCESS,payload : []})
}}


export const getRedirectUrl = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.get('/facebook/get-fb-redirect-url/')
      .then((response) => {
        dispatch({ type: types.GET_FACEBOOK_REDIRECT_URL_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_FACEBOOK_REDIRECT_URL_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetGetRedirectUrl = () => {
  return dispatch => {
     dispatch({type : types.GET_FACEBOOK_REDIRECT_URL_SUCCESS,payload : []})
}}

export const getAppKeys = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.get('/facebook/get-fb-app-keys/')
      .then((response) => {
        dispatch({ type: types.GET_FACEBOOK_APP_KEYS_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_FACEBOOK_APP_KEYS_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetGetAppKeys = () => {
  return dispatch => {
     dispatch({type : types.GET_FACEBOOK_APP_KEYS_SUCCESS,payload : []})
}}

export const commentPost = (data, rid) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.post('/facebook/comment-post/', data)
      .then((response) => {
        dispatch({ type: types.COMMENT_FACEBOOK_PAGE_POST_SUCCESS, payload: response.data, rid: rid });
      })
      .catch((error) => {
        dispatch({ type: types.COMMENT_FACEBOOK_PAGE_POST_FAILED, payload: (error.response.data), rid: rid});
      });
  };
};

export const resetCommentPost = () => {
  return dispatch => {
     dispatch({type : types.COMMENT_FACEBOOK_PAGE_POST_SUCCESS, payload : [], rid: -1})
}}

export const getPostComments = (data) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.post('/facebook/bot-operator-activity-by-newsfeedId', data)
      .then((response) => {
        dispatch({ type: types.GET_POST_COMMENT_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_POST_COMMENT_FAILED, payload: (error.response.data)});
      });
  };
};

export const resetGetPostComments = () => {
  return dispatch => {
     dispatch({type : types.GET_POST_COMMENT_SUCCESS, payload : []})
}}